import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { DeclaranteBvg } from '../../../models/cpj/DeclaranteBvg';
import { PersonaJuridicaService } from '../../../service/personaNatural/PersonaJuridicaService';
import { Fieldset } from 'primereact/fieldset';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { NotificadoBVG } from './NotificadoBVG';
import { Button } from 'primereact/button';
import { Generico } from '../../../components/Generico';

export const RepresentanteBVG = ({ generic, toast, id, permss, catalogos, handlePrev, handleNext, nuevoCliente }) => {

    const omodel = new DeclaranteBvg();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    const juridicaSrv = new PersonaJuridicaService();
    const [tieneConyugue, setTieneConyugue] = useState(false);
    const formRef = useRef(null);
    const { inDrop, inText, inCalendar, inMask } = InputComponent({ omodel, generic, errors, control, permss, id: getValues('id') });
    const { stringToDate } = Generico();

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.id = id;
        data.casado = catalogos.casado;
        data.union = catalogos.union;
        data.seccion = "seccion2";
        data.fecha_nacimiento = data.fecha_nacimiento.toISOString().split('T')[0];
        data.telefono_rep = data.telefono_rep?.replaceAll("_", '');
        if(!tieneConyugue) {
            delete data.nombre_completo_cy;
            delete data.identificacion_cy;
            delete data.tipo_identificacion_cy;
            delete data.nacionalidad_cy;
            delete data.profesion_cy;
        }
        juridicaSrv.editarCliente(data).then(res => {
            nuevoCliente({representante: data.apellidos_rep + ' ' + data.nombres_rep});
            handleNext();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        juridicaSrv.consultarCliente(id, 'seccion2').then(res => {
            let _cat = catalogos;
            let _dec = res.data.clientepj;
            const lista = {
                pais_residencia: _cat.pais,
                genero: _cat.genero,
                nacionalidad_rep: _cat.nacionalidad,
                estado_civil: _cat.estado_civil,
                tipo_identificacion_rep: _cat.tipo_per,
                tipo_rep: _cat.tipo_rep
            };
            let _tieneConyugue = false;
            _cat.casado.forEach(itm => {
                if (itm.cat_id === _dec.estado_civil) {
                    _tieneConyugue = true;
                }
            });
            setTieneConyugue(_tieneConyugue);
            if(_tieneConyugue){
                lista.tipo_identificacion_cy = _cat.tipo_per;
                lista.nacionalidad_cy = _cat.nacionalidad;
            }

            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            if(_dec.fecha_nacimiento) _dec.fecha_nacimiento = stringToDate(_dec.fecha_nacimiento);

            reset(generic.renewValues(_dec, omodel.model));
        });
    }

    const onChangeEstadoCivil = (e) => {
        let _tiene = false;
        if (e.value) {
            catalogos.casado.forEach(itm => {
                if (itm.cat_id === e.value.cat_id) {
                    _tiene = true;
                }
            });
        }
        setTieneConyugue(_tiene);
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const siguiente = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(
                new Event('submit', { bubbles: true, cancelable: true })
            );
        }
    };

    return (
        <>
            <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
                <Panel header="2.- Datos Apoderado General o Representante Legal" className="panel-ficha">
                    <div className="formgrid grid">
                        {inDrop('tipo_rep', catalogos?.tipo_rep, { lab: omodel.optionLabel })}
                        {inText('nombres_rep')}
                        {inText('apellidos_rep')}
                        {inDrop('nacionalidad_rep', catalogos?.nacionalidad, { lab: omodel.optionLabel })}

                        {inDrop('tipo_identificacion_rep', catalogos?.tipo_per, { lab: omodel.optionLabel })}
                        {inText('identificacion_rep')}
                        {inDrop('pais_residencia', catalogos?.pais, { lab: omodel.optionLabel })}
                        {inDrop('estado_civil', catalogos?.estado_civil, { lab: omodel.optionLabel, chn: onChangeEstadoCivil })}

                        {inDrop('genero', catalogos?.genero, { lab: omodel.optionLabel })}
                        {inText('lugar_nacimiento')}
                        {inCalendar('fecha_nacimiento')}
                        {inText('direccion_residencia')}

                        {inMask('telefono_rep')}
                        {inText('profesion_rep')}
                        {inText('tiempo_rep')}
                        {inText('correo_rep')}
                    </div>

                    {tieneConyugue && <Fieldset legend="Datos del Cónyuge/Conviviente (Si es aplicable)" >
                        <div className="formgrid grid">
                            {inText('nombre_completo_cy')}
                            {inDrop('tipo_identificacion_cy', catalogos?.tipo_per, { lab: omodel.optionLabel })}
                            {inText('identificacion_cy')}
                            {inDrop('nacionalidad_cy', catalogos?.nacionalidad, { lab: omodel.optionLabel })}

                            {inText('profesion_cy')}
                        </div>
                    </Fieldset>
                    }
                </Panel>
            </form>
            <br />

            <NotificadoBVG idp={id} generic={generic} toast={toast} permss={permss} />
            <br />

            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />&nbsp;
                    <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => siguiente()} />
                </div>
            </div>
        </>
    )
}
