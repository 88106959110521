import { useEffect, useRef, useState } from "react";
import { Busqueda } from "../../models/Busqueda";
import { GenericComponent } from "../../components/GenericComponent";
import { useForm } from "react-hook-form";
import { ConsultaService } from "../../service/ConsultaService";
import { InputComponent } from "../../components/InputComponent";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Panel } from 'primereact/panel';
import '../../components/estilo.css';

export const Consulta = () => {

    const generic = new GenericComponent();
    const omodel = new Busqueda();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, getValues, setError } = useForm({ defaultValues });
    const consultaSrv = new ConsultaService();
    const toast = useRef(null);
    const [dialogBuscar, setDialogBuscar] = useState(false);
    let loadLazyTimeout = null;
    const [paginas, setPaginas] = useState(null);
    const [loadExcel, setLoadExcel] = useState(false);
    const [loadPdf, setLoadPdf] = useState(false);
    const [info, setInfo] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText } = InputComponent({ registers: paginas, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            consultaSrv.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setPaginas(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const onSubmit = (data) => {
        setInfo(data);
        setDialogBuscar(true);
    }

    const exportar = (tipo) => {
        let data = info;
        data.tipo = tipo;
        setLoadExcel(tipo === 'xlsx');
        setLoadPdf(tipo === 'pdf');
        consultaSrv.buscarPrincipal(data).then(res => {
            if(res.headers['x-consulta']) {
                const tip = res.headers['x-consulta'];
                let msg = '';
                if(tip.includes('judicial')) msg = 'Consulta de procesos judiciales';
                if(tip.includes('catastro')) msg += (msg === '' ? '' : ' y ') + 'Consulta de RUC';
                msg = 'Las siguientes páginas de consultas presentan intermitencias: ' + msg;
                toast.current.show({ severity: 'error', summary: 'Error al generar', detail: msg, life: 6000 });
            }
            setLoadExcel(false);
            setLoadPdf(false);
            generic.downloadPdf(res.data, 'Reporte.' + tipo);
        }).catch(error => {
            setLoadExcel(false);
            setLoadPdf(false);
            if (error.response?.data?.errors !== undefined) {
                generic.funcError(error, toast, setError);
                setDialogBuscar(false);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error al generar', detail: 'No se puede generar el documento. Inténtelo más tarde.' });
            }
        });
    }

    const cancelar = () => {
        setDialogBuscar(false);
        reset(defaultValues);
    }

    return (
        <>
            <Toast ref={toast} />
            <Card header="Validación de Información" style={{ width: '100%', margin: 'auto' }} className="card-ficha">
                <Panel header="VALIDACIÓN" style={{ width: '25rem', margin: 'auto' }} className="panel-ficha" >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {inText('nombres', {}, 12)}
                        {inText('identificacion', {}, 12)}
                        <Button label="Validar" style={{ width: '100%' }} type="submit" icon="pi pi-check-circle" className="p-button-warning" />
                    </form>
                </Panel>
            </Card>

            <Dialog visible={dialogBuscar} header="Listado de Bases de Datos" modal className="p-fluid modal-ssize" onHide={e => cancelar()}>
                {table()}
                <span style={{ fontSize: '9px', maxWidth: '150px' }}>
                    <b>* En Línea o Fuera de línea</b> (en el reporte) es el momento exacto en que se realiza las consultas y <br />
                    justamente en ese instante presentó las páginas web algún inconveniente.
                </span>
                <div className="field col-12" style={{ textAlign: 'center' }}>
                    <Button onClick={() => { exportar('pdf') }} className="p-button-warning mr-2" data-pr-tooltip="PDF" type="button"
                        title='Generar en formato PDF' icon="pi pi-file-pdf" loading={loadPdf} disabled={loadExcel} />
                    <Button type="button" title='Generar a formato Excel' icon="pi pi-file-excel" onClick={() => { exportar('xlsx') }}
                        className="p-button-success mr-2" data-pr-tooltip="XLS" loading={loadExcel} disabled={loadPdf} />
                    <Button onClick={() => { cancelar() }} className="p-button-warning mr-2" data-pr-tooltip="PDF" type="button" title='Cancelar' icon="pi pi-times"
                        disabled={loadExcel || loadPdf} />
                </div>
            </Dialog>
        </>
    )
}
