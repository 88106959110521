export class ConvivienteAcc {

    model = {
        id: { value: null, export: false },
        nombre_completo_cy: {
            value: '',
            header: 'Apellidos y Nombres Completos:',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 500 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 3 caracteres',
            max: 500,
            min: 3,
        },
        nacionalidad_cy: {
            value: null,
            header: 'Nacionalidad',
            required: 'Nacionalidad es requerido'
        },
        tipo_identificacion_cy: {
            value: null,
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
        },
        identificacion_cy: {
            value: '',
            header: 'Identificación',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 50,
            min: 3,
        },
        pais_residencia_cy: {
            value: null,
            header: 'País de Residencia',
            required: 'País de Residencia es requerido'
        },
        genero_cy: {
            value: null,
            header: 'Sexo',
            required: 'Seleccione una opción'
        },
        lugar_nacimiento_cy: {
            value: '',
            header: 'Lugar de Nacimiento:',
            required: 'Campo es requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        fecha_nacimiento_cy: {
            value: new Date(),
            header: 'Fecha de nacimiento:',
            required: 'Fecha es requerido'
        },
        lugar_trabaja_cy: {
            value: '',
            header: 'Lugar donde trabaja:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        cargo_cy: {
            value: '',
            header: 'Cargo que desempeña:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        correo_electronico_cy: {
            value: '',
            header: 'Correo electrónico:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        telefono_cy: {
            value: '',
            header: 'Teléfono/Celular:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        tiene_hijos: {
            value: null,
            header: '¿Tiene hijos?',
            required: 'Seleccione una opción',
        },
    }
    conv = <span style={{ textAlign: 'justify', fontSize: '10px' }}><b>Datos del Cónyuge/Conviviente (Si es aplicable)</b></span>

    optionLabel = 'nombre';
}
