import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Generico } from '../../../components/Generico';
import { FuncionarioService } from '../../../service/ficha/FuncionarioService';
import { Hijos } from './Hijos';
import { Divider } from 'primereact/divider';
import { ConvivienteAcc } from '../../../models/fun/ConvivienteAcc';

export const ConyugueAccionista = ({ generic, toast, id, permss, catalogos, handlePrev, handleNext }) => {

    const omodel = new ConvivienteAcc();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    const accSrv = new FuncionarioService();
    const formRef = useRef(null);
    const { inDrop, inText, inCalendar, inMask } = InputComponent({ omodel, generic, errors, control, permss, id: getValues('id') });
    const { stringToDate } = Generico();
    const [casado, setCasado] = useState(false);

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.id = id;
        data.casado = catalogos.casado;
        data.union = catalogos.union;
        data.seccion = "seccion2";
        data.si_casado = casado;
        if(casado) {
            data.fecha_nacimiento_cy = data.fecha_nacimiento_cy.toISOString().split('T')[0];
            data.telefono_cy = data.telefono_cy?.replaceAll("_", '');
        }
        accSrv.editar(data, 'accionista-pn-bvg').then(res => {
            handleNext();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        accSrv.consultar(id, 'accionista-pn-bvg', 'seccion2').then(res => {
            const _cat = catalogos;
            const _dec = res.data.acc;
            if (!_dec) {
                reset(defaultValues);
                return;
            }
            const lista = {
                nacionalidad_cy: _cat.nacionalidad,
                tipo_identificacion_cy: _cat.tipo,
                pais_residencia_cy: _cat.pais,
                genero_cy: _cat.genero,
                tiene_hijos: generic.sino
            };
            setCasado(_dec?.si_casado);
            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            if (_dec.fecha_nacimiento_cy && _dec?.si_casado) _dec.fecha_nacimiento_cy = stringToDate(_dec.fecha_nacimiento_cy);

            reset(generic.renewValues(_dec, omodel.model));
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const siguiente = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(
                new Event('submit', { bubbles: true, cancelable: true })
            );
        }
    };


    return (
        <>
            <Panel header="1.- Datos Generales / Personales:" className="panel-ficha">
                {casado && omodel.conv}
                {casado && <Divider />}

                <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {casado && inText('nombre_completo_cy')}
                        {casado && inDrop('nacionalidad_cy', catalogos?.nacionalidad, { lab: omodel.optionLabel })}
                        {casado && inDrop('tipo_identificacion_cy', catalogos?.tipo, { lab: omodel.optionLabel })}
                        {casado && inText('identificacion_cy')}

                        {casado && inDrop('pais_residencia_cy', catalogos?.pais, { lab: omodel.optionLabel })}
                        {casado && inDrop('genero_cy', catalogos?.genero, { lab: omodel.optionLabel })}
                        {casado && inText('lugar_nacimiento_cy')}
                        {casado && inCalendar('fecha_nacimiento_cy')}


                        {casado && inText('lugar_trabaja_cy')}
                        {casado && inText('cargo_cy')}
                        {casado && inText('correo_electronico_cy')}
                        {casado && inMask('telefono_cy')}
                        {inDrop('tiene_hijos', generic.sino, { lab: omodel.optionLabel })}
                    </div>
                </form>
                <Hijos id={id} toast={toast} permss={permss} opcion="accionista-hijo-bvg" />
                <br />

            </Panel>
            <br />

            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />&nbsp;
                    <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => siguiente()} />
                </div>
            </div>
        </>
    )
}
