import { ConfirmDialog } from "primereact/confirmdialog"
import { Cliente } from "./Cliente"

export const InfoClientes = () => {
    return (
        <>
            <ConfirmDialog />
            <Cliente tipo="cliente" />
        </>
    )
}
