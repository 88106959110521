import Axios from "axios";

export class InfoClienteService {

    lista(params, op) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/${op}/lista?${queryParams}`).then(res => res.data);
    }

    nuevo(data, op) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/matriz/${op}/nuevo`, data).then(res => res.data);
    }

    editar(data, op) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/matriz/${op}/editar`, data).then(res => res.data);
    }

    consultar(id, op) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/${op}/consulta/${id}`).then(res => res.data);
    }

    eliminar(id, op) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/matriz/${op}/eliminar/${id}`).then(res => res.data);
    }

    informacion(id, op) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/${op}/informacion/${id}`).then(res => res.data);
    }

    detalle(data, op) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/matriz/${op}-detalle/procesar`, data).then(res => res.data);
    }

    finalizar(data, op) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/matriz/${op}-estado/procesar`, data).then(res => res.data);
    }

    descargar(id, op) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/${op}/reporte/${id}`, { headers: { 'Content-Type': 'application/json', 'Pragma': 'no-cache' },
            responseType: 'blob'}).then(async res => res.data);
    }

    getLista(nombre, nemonico, valor) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/matriz/nbuscar/${encodeURIComponent(nemonico)}/${encodeURIComponent(valor)}/${encodeURIComponent(nombre)}`).then(res => res);
    }
}
