import Axios from "axios";

export class ConfiguracionArchivoService {

    getArchivo(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/config-archivo/lista?${queryParams}`).then(res => res.data);
    }

    newArchivo(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/ficha/config-archivo/nuevo`, data).then(res => res.data);
    }

    editarArchivo(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/ficha/config-archivo/editar`, data).then(res => res.data);
    }

    consultarArchivo(id, iniciar) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/ficha/config-archivo/consulta/${id}`).then(res => res.data);
    }

    eliminarArchivo(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/ficha/config-archivo/eliminar/${id}`).then(res => res.data);
    }

    listaRol(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/adjunto-rol/lista?${queryParams}`).then(res => res.data);
    }

    asignarPermiso(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/adjunto-rol/rol-asignar`, data).then(res => res.data);
    }
}
