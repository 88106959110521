import { useForm } from "react-hook-form";
import { GenericComponent } from "../../../components/GenericComponent";
import { Generico } from "../../../components/Generico";
import { Directorio } from "../../../models/fun/Directorio";
import { DirectorioServicio } from "../../../service/ficha/DirectorioServicio";
import { useEffect, useState } from "react";
import { InputComponent } from "../../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";

export const DirectorioAccion = ({ id, toast, permss, directorio }) => {
    const generic = new GenericComponent();
    const { accionesTbl } = Generico();
    const omodel = new Directorio(directorio);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    const directorioSrv = new DirectorioServicio();
    const [registros, setRegistros] = useState(null);
    const [dialogDirectorio, setDialogDirectorio] = useState(false);
    const [accion, setAccion] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText, inNumber } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazy();
    }, [id]);

    const editCargo = () => {
        directorioSrv.consultar(accion?.id, id, directorio).then(res => {
            reset(res.data);
            setDialogDirectorio(true);
        });
    }

    const loadLazy = () => {
        setLoading(true);

        let _lazyParams = lazyParams;
        _lazyParams.idp = id;
        _lazyParams.directorio = directorio;
        directorioSrv.getLista({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
            setTotalRecords(res.data.total);
            setRegistros(res.data.registros);
            setLoading(false);
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.directorio = directorio;
        if (getValues('id') == null) {
            directorioSrv.nuevo(data).then(res => {
                setDialogDirectorio(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            directorioSrv.editar(data).then(res => {
                setDialogDirectorio(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const openNew = () => {
        setAccion(null);
        setDialogDirectorio(true);
        reset(defaultValues);
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteAccion()
        });
    };

    const deleteAccion = () => {
        directorioSrv.eliminar(accion?.id, id, directorio).then(res => {
            loadLazy();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!accion?.nemonico || accion.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editCargo() } });
        if (permss?.eliminar && (!accion?.nemonico || accion?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} title="Registrar información"
                        style={{ display: permss?.crear ? 'block' : 'none' }} />
                    <span className="font-bold">{directorio === true ? omodel.msgDirectorio : omodel.msgAccion}</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <Panel headerTemplate={headerTemplate} className="panel-ficha col-12">
                {table({ action01: accionesTbl, reg: setAccion, items: itemsMenu() })}
            </Panel>

            <Dialog visible={dialogDirectorio} header={getValues('id') == null ? "Nueva Información" : "Editar Información"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogDirectorio(false)} style={{ width: '400px' }}>
                <div onSubmit={(e) => e.stopPropagation()}>
                    <br />
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="formgrid grid">
                            {inText('institucion', {}, 12)}
                            {directorio === false && inNumber('participacion', 2, false, {}, 12)}
                        </div>

                        {generic.buttonsForms(setDialogDirectorio)}
                    </form>
                </div>
            </Dialog>
        </>
    );
}
