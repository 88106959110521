import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { useEffect, useState } from "react";
import { FuncionarioService } from "../../../service/ficha/FuncionarioService";
import { Panel } from "primereact/panel";
import { InputComponent } from "../../../components/InputComponent";
import { GenericComponent } from "../../../components/GenericComponent";
import { Generico } from "../../../components/Generico";
import { HijoBvg } from "../../../models/fun/HijoBvg";
import { Button } from "primereact/button";

export const Hijos = ({ id, toast, permss, opcion }) => {

    const generic = new GenericComponent();
    const omodel = new HijoBvg();
    const { accionesTbl } = Generico();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    let loadLazyTimeout = null;
    const funSrv = new FuncionarioService();
    const [registros, setRegistros] = useState(null);
    const [dialogHijo, setDialogHijo] = useState(false);
    const [hijo, setHijo] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inText, inInteger, inMask } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazy();
    }, [id]);

    const editHijo = () => {
        funSrv.consultar(hijo?.id, opcion, id).then(res => {
            reset(res.data);
            setDialogHijo(true);
        });
    }

    const loadLazy = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        let _lazyParams = lazyParams;
        _lazyParams.idp = id;

        loadLazyTimeout = setTimeout(() => {
            funSrv.listado({ lazyEvent: JSON.stringify(_lazyParams) }, opcion).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
            });
        });
    }

    const onSubmit = (data) => {
        data.idp = id;
        data.telefono = data.telefono?.replaceAll("_", '');
        if (getValues('id') == null) {
            funSrv.nuevo(data, opcion).then(res => {
                setDialogHijo(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            funSrv.editar(data, opcion).then(res => {
                setDialogHijo(false);
                loadLazy();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const openNew = () => {
        setHijo(null);
        setDialogHijo(true);
        reset(defaultValues);
    }

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteHijo()
        });
    };

    const deleteHijo = () => {
        funSrv.eliminar(hijo?.id, opcion, id).then(res => {
            loadLazy();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!hijo?.nemonico || hijo?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editHijo() } });
        if (permss?.eliminar && (!hijo?.nemonico || hijo?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const headerTemplate = (options) => {
        return (
            <div className={options.className}>
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" type="button" onClick={openNew} title="Registrar información"
                        style={{ display: permss?.crear ? 'block' : 'none' }} />
                    <span className="font-bold">DATOS DE LOS HIJOS</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <Panel headerTemplate={headerTemplate}>
                {table({ action01: accionesTbl, reg: setHijo, items: itemsMenu() })}
            </Panel>

            <Dialog visible={dialogHijo} header={getValues('id') == null ? "Nueva Información" : "Editar Información"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogHijo(false)} style={{ width: '600px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {inText('nombre_completo', {}, 6)}
                        {inInteger('edad', 6)}
                        {inText('ocupacion', {}, 6)}
                        {inText('lugar_trabajo', {}, 6)}
                        {inText('identificacion', {}, 6)}
                        {inMask('telefono', {}, 6)}
                    </div>

                    {generic.buttonsForms(setDialogHijo)}
                </form>
            </Dialog>
        </>
    )
}
