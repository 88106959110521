import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { GenericComponent } from '../../components/GenericComponent';
import { InputComponent } from '../../components/InputComponent';
import { Declarante } from '../../models/Declarante';
import { CatalogoService } from '../../service/CatalogoService';
import { DeclaranteService } from '../../service/DeclaranteService';
import { confirmDialog } from 'primereact/confirmdialog';

export const Declarantes = ({ toast, tipo, id, items, permss }) => {

    const generic = new GenericComponent();
    const omodel = new Declarante();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, trigger } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [declarantes, setDeclarantes] = useState([]);
    const declaranteService = new DeclaranteService();
    const [dialogDeclarante, setDialogDeclarante] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [esRuc, setEsRuc] = useState(false);
    const [ecuador, setEcuador] = useState(null);
    const [ciudades, setCiudades] = useState(null);
    const catalogoService = new CatalogoService();
    const [esCedula, setEsCedula] = useState(false);
    const [tieneConyugue, setTieneConyugue] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inArea, inCalendar } =
        InputComponent({ registers: declarantes, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        setCatalogos(items);
    }, [items]);

    const loadLazyDeclarante = (idp) => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            _lazyParams.idp = idp == null ? id : idp;
            _lazyParams.tipo = tipo;
            declaranteService.getDeclarante({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setDeclarantes(res.data.registros);
                setLoading(false);
            });
        });
    }

    const openNew = () => {
        if (catalogos == null) {
            declaranteService.newDeclarante({ iniciar: 1 }).then(res => {
                setCatalogos(res.data);
                setDialogDeclarante(true);
                reset(defaultValues);
            });
        }
        else {
            setDialogDeclarante(true);
            reset(defaultValues);
        }
    }

    const openNewProveedor = () => {
        setDialogDeclarante(true);
        reset(defaultValues);
    }

    const hideDialog = () => { setDialogDeclarante(false) }

    const onSubmit = (data) => {
        data.idp = id;
        data.tipo = tipo;
        data.ruc = catalogos.ruc;
        if (getValues('id') == null) {
            declaranteService.newDeclarante(data).then(res => {
                setDialogDeclarante(false);
                loadLazyDeclarante();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            declaranteService.editarDeclarante(data).then(res => {
                setDialogDeclarante(false);
                loadLazyDeclarante();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const onSubmitProveedor = (data) => {
        data.idp = id;
        data.tipo = tipo;
        data.ruc = catalogos.ruc;
        data.ec = catalogos.ecuador;
        data.casado = catalogos.casado;
        data.union = catalogos.union;
        data.fecha_nacimiento = generic.dateToString(data.fecha_nacimiento);
        if (getValues('id') == null) {
            declaranteService.newProveedor(data).then(res => {
                setDialogDeclarante(false);
                loadLazyDeclarante();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            declaranteService.editarProveedor(data).then(res => {
                setDialogDeclarante(false);
                loadLazyDeclarante();
                generic.funcSuccess(res, toast);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editDeclProveedor = (rowData) => {
        const iniciar = catalogos == null ? 1 : 0;
        declaranteService.consultarProveedor(rowData.id, 'proveedor', id, iniciar).then(res => {
            let _cat = catalogos;
            if(iniciar == 1) {
                setCatalogos(res.data.catalogos);
                _cat = res.data.catalogos;
            }
            let _dec = res.data.declarante;
            let lista = {
                tipo_declarante: _cat.declarante, tipo_identificacion: _cat.tipo_identificacion, nacionalidad: _cat.nacionalidad, genero: _cat.genero, estado_civil: _cat.estado_civil,
                pais_id: _cat.pais,
            };
            if(_dec.pais_id == _cat.ecuador.cat_id){
                lista['provincia_id'] = _cat.provincias;
                lista['ciudad_id'] = res.data.ciudades;
                setCiudades(res.data.ciudades);
            }
            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            _dec.fecha_nacimiento = generic.stringToDate(_dec.fecha_nacimiento);
            setEsRuc(JSON.stringify(_dec.tipo_identificacion) === JSON.stringify(_cat.ruc));
            setEsCedula(JSON.stringify(_dec.tipo_identificacion) === JSON.stringify(_cat.cedula));
            setEcuador(JSON.stringify(_dec.pais_id) === JSON.stringify(_cat.ecuador));
            setTieneConyugue(JSON.stringify(_dec.estado_civil) === JSON.stringify(_cat.casado) || JSON.stringify(_dec.estado_civil) === JSON.stringify(_cat.union));
            reset(generic.renewValues(_dec, omodel.model));
            setDialogDeclarante(true);
        });
    }

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar el declarante?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteDeclarante(data)
        });
    }

    const deleteDeclarante = (data) => {
        declaranteService.eliminarDeclarante(data.id, tipo, id).then(res => {
            loadLazyDeclarante();//Proveedor
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const tableDeclarante = () => {
        return (
            <>
                {id && <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, '', permss)}></Toolbar>}
                {table()}
            </>
        )
    }

    const tableDeclaranteProveedor = () => {
        return (
            <>
                {id && <Toolbar className="mb-4" end={generic.leftToolbarTemplate(openNewProveedor, '', permss)}></Toolbar>}
                {table({ action: generic.actionEditTemplate, methEdit: editDeclProveedor, perm: permss, methDelete: confirmDelete })}
            </>
        )
    }

    const onChangeTipo = (e, opc) => {
        setEsRuc(JSON.stringify(e.value) === JSON.stringify(catalogos.ruc));
        setEsCedula(JSON.stringify(e.value) === JSON.stringify(catalogos.cedula));
        trigger('tipo_identificacion');
    }

    const onChangePais = (e, opt) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeEstadoCivil = (e, opt) => {
        setTieneConyugue(JSON.stringify(e.value) === JSON.stringify(catalogos.casado) || JSON.stringify(e.value) === JSON.stringify(catalogos.union));
    }

    const onChangeProvincia = (e, opt) => {
        catalogoService.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }

    const dialogoDeclarante = () => {
        return (
            <Dialog visible={dialogDeclarante} header={getValues('id') == null ? "Nuevo declarante" : "Editar declarante"} modal className="p-fluid modal-ssize" onHide={e => setDialogDeclarante(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_declarante', catalogos?.declarante, { lab: omodel.optionLabel })}
                    </div>

                    <div className="formgrid grid">
                        {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel, chn: onChangeTipo, val: null })}
                        {inText('identificacion', { readOnly: getValues('tipo_identificacion') == null })}
                    </div>

                    <div className="formgrid grid">
                        {esRuc && inText('razon_social')}
                        {!esRuc && inText('nombres')}
                        {!esRuc && inText('apellidos')}
                    </div>

                    {generic.buttonsForms(hideDialog)}
                </form>
            </Dialog>
        )
    }

    const dialogoDeclaranteProveedor = () => {
        return (
            <Dialog visible={dialogDeclarante} header={getValues('id') == null ? "Nuevo declarante" : "Editar declarante"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogDeclarante(false)}>
                <form onSubmit={handleSubmit(onSubmitProveedor)}>
                    <div className="formgrid grid">
                        {inDrop('tipo_declarante', catalogos?.declarante, { lab: omodel.optionLabel }, 4)}
                        {inDrop('tipo_identificacion', catalogos?.tipo_identificacion, { lab: omodel.optionLabel, chn: onChangeTipo }, 4)}
                        {!esRuc && !esCedula && inText('identificacion', {}, 4)}
                        {esRuc && inText('identificacion', generic.infoIdentificacion('ruc'), 4)}
                        {esCedula && inText('identificacion', generic.infoIdentificacion('cedula'), 4)}
                    </div>

                    <div className="formgrid grid">
                        {esRuc && inText('razon_social', {}, 8)}
                        {!esRuc && inText('nombres', {}, 4)}
                        {!esRuc && inText('apellidos', {}, 4)}
                        {inCalendar('fecha_nacimiento', {}, 4)}
                    </div>

                    <div className="formgrid grid">
                        {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel }, 4)}
                        {inDrop('genero', catalogos?.genero, { lab: omodel.optionLabel }, 4)}
                        {inDrop('estado_civil', catalogos?.estado_civil, { lab: omodel.optionLabel, chn: onChangeEstadoCivil }, 4)}
                    </div>

                    <div className="formgrid grid">
                        {inDrop('pais_id', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false }, 4)}
                        {ecuador && inDrop('provincia_id', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia}, 4)}
                        {ecuador && inDrop('ciudad_id', ciudades, { lab: omodel.optionLabel }, 4)}
                        {!ecuador && inText('ciudad', {}, 8)}
                    </div>

                    <div className="formgrid grid">
                        {inArea('direccion_domicilio', 5, 100, 4)}
                        {inText('telefono_celular', {}, 4)}
                        {inText('telefono_fijo', {}, 4)}
                    </div>

                    <div className="formgrid grid">
                        {tieneConyugue && inText('nombres_conyugue', {}, 4)}
                        {tieneConyugue && inText('apellidos_conyugue', {}, 4)}
                        {tieneConyugue && inText('identificacion_conyugue', {}, 4)}
                    </div>

                    {generic.buttonsForms(hideDialog)}
                </form>
            </Dialog>
        )
    }

    return {
        loadLazyDeclarante,
        tableDeclarante,
        dialogoDeclarante,
        dialogoDeclaranteProveedor,
        tableDeclaranteProveedor
    }
}
