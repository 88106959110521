import React, { useEffect, useRef } from 'react';
import { AccionistaSocioBvg } from './AccionistaSocioBvg';
import { Panel } from 'primereact/panel';
import { CapitalBvg } from '../../../models/cpj/CapitalBvg';
import { useForm } from 'react-hook-form';
import { PersonaJuridicaService } from '../../../service/personaNatural/PersonaJuridicaService';
import { InputComponent } from '../../../components/InputComponent';
import { Button } from 'primereact/button';

export const SocAccBVG = ({ toast, id, permss, catalogos, generic, handleNext, handlePrev, seccion }) => {
    const omodel = new CapitalBvg();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    const juridicaSrv = new PersonaJuridicaService();
    const formRef = useRef(null);
    const { inNumber } = InputComponent({ omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        editFicha();
    }, [id]);

    const editFicha = () => {
        juridicaSrv.consultarCliente(id, seccion).then(res => {
            reset(generic.renewValues(res.data.clientepj, omodel.model));
        });
    }

    const onSubmit = (data) => {
        data.id = id;
        data.seccion = seccion;
        juridicaSrv.editarCliente(data).then(res => {
            handleNext();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const siguiente = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(
                new Event('submit', { bubbles: true, cancelable: true })
            );
        }
    };

    return (
        <>
            <Panel header="3.- Capital / Acción / Participación" className="panel-ficha">
                <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {inNumber('capital_suscrito', 2, true, {}, 4)}
                        {inNumber('capital_autorizado', 2, true, {}, 4)}
                        {inNumber('participacion', 2, true, {}, 4)}
                    </div>
                </form>
            </Panel>
            <br />

            <AccionistaSocioBvg idp={id} permss={permss} catalogos={catalogos} toast={toast} origen={false} />
            <br />

            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />&nbsp;
                    <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => siguiente()} />
                </div>
            </div>
        </>
    );
}
