import { useForm } from "react-hook-form";
import { GenericComponent } from "../../components/GenericComponent";
import { Generico } from "../../components/Generico";
import { InfoCliente } from "../../models/matriz/InfoCliente";
import { InfoClienteService } from "../../service/matriz/InfoClienteService";
import { useEffect, useRef, useState } from "react";
import { InputComponent } from "../../components/InputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Busqueda } from "./Busqueda";
import { DetalleProveedor } from "./DetalleProveedor";
import { useToken } from "../../components/useToken";

export const Proveedor = ({ tipo }) => {
    const omodel = new InfoCliente();
    const generic = new GenericComponent();
    const clienteSrv = new InfoClienteService();
    const defaultValues = generic.default(omodel.model);
    const { accionesTbl, funcMessageBlobError, downloadPdf } = Generico();
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    const [registers, setRegisters] = useState(null);
    const [catalogo, setCatalogo] = useState(null);
    const [permss, setPermss] = useState(null);
    const toast = useRef(null);
    const [dialogCliente, setDialogCliente] = useState(false);
    const [dialogPerfil, setDialogPerfil] = useState(false);
    const [matriz, setMatriz] = useState(null);
    const [dialogBusqueda, setDialogBusqueda] = useState(false);
    const [dialogNatural, setDialogNatural] = useState(false);
    const { getRol } = useToken();
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inCalendar } = InputComponent({ registers, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = 'permiso';
        }
        if (getRol()) {
            _lazyParams.rol = getRol();
        }
        clienteSrv.lista({ lazyEvent: JSON.stringify(_lazyParams) }, tipo).then(res => {
            setTotalRecords(res.data.total);
            setRegisters(res.data.registros);
            if (catalogo == null) {
                setCatalogo(res.data.catalogos);
                setPermss(res.data.perss);
            }
            setLoading(false);
        });
    }

    const openNew = () => {
        reset(defaultValues);
        setDialogCliente(true);
    }

    const edit = () => {
        clienteSrv.consultar(matriz.id, tipo).then(res => {
            values(res.data);
        });
    }

    const values = (_cliente) => {
        const lista = { tipo_identificacion: catalogo?.tipo_identificacion, };
        Object.keys(lista).forEach(function (key) {
            if (_cliente[key] != null) {
                lista[key].forEach(element => {
                    if (element.cat_id === _cliente[key]) {
                        _cliente[key] = element;
                    }
                });
            }
        });
        _cliente.fecha = generic.stringToDate(_cliente.fecha);
        if (typeof _cliente.tipo_identificacion === 'number') _cliente.tipo_identificacion = null;

        reset(generic.renewValues(_cliente, omodel.model));
        setDialogCliente(true);
        setDialogBusqueda(false);
    }

    const onSubmit = (data) => {
        data.fecha = generic.dateToString(data.fecha);
        if (getValues('id') == null) {
            clienteSrv.nuevo(data, tipo).then(res => {
                generic.funcSuccess(res, toast);
                setDialogCliente(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        } else {
            clienteSrv.editar(data, tipo).then(res => {
                generic.funcSuccess(res, toast);
                loadLazyData();
                setDialogCliente(false);
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const deleteItem = () => {
        confirmDialog({
            message: '¿Esta seguro de eliminar la matriz?',
            header: 'Confirmar',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            accept: () => {
                clienteSrv.eliminar(matriz.id, tipo).then(res => {
                    generic.funcSuccess(res, toast);
                    loadLazyData();
                }).catch(error => {
                    generic.funcDeleteError(error, toast);
                });
            }
        });
    }

    const startContent = (
        <>
            {permss?.crear && <Button icon="pi pi-plus" className="mr-2" tooltip="Nueva matriz" onClick={openNew} />}
            {permss?.actualizar && <Button icon="pi pi-search" className="mr-2" tooltip="Búsqueda Declaración Persona Jurídica: Proveedor" onClick={() => setDialogBusqueda(true)} />}
            {permss?.actualizar && <Button icon="pi pi-search-plus" className="mr-2" tooltip="Búsqueda Declaración Persona Natural: Proveedor" onClick={() => setDialogNatural(true)} />}
        </>
    );

    const generarReporte = () => {
        setLoading(true);
        clienteSrv.descargar(matriz?.id, tipo).then(res => {
            downloadPdf(res, 'Matriz-proveedor.pdf');
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            funcMessageBlobError('Asegúrese de ingresar y guardar la información antes de generar el reporte.', error, toast);
        });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!matriz?.nemonico || matriz?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar información de inicio', icon: 'pi pi-pencil', command: () => { edit() } });
        if (permss?.actualizar && (!matriz?.nemonico || matriz?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Contenido de la matriz', icon: 'pi pi-qrcode', command: () => { setDialogPerfil(true) } });
        if (permss?.consultar && matriz?.nemonico === 'FINALIZADO')
            items.push({ label: 'Imprimir matriz Análisis a PDF', icon: 'pi pi-print', command: () => { generarReporte() } });
        if (permss?.eliminar && (!matriz?.nemonico || matriz?.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { deleteItem() } });

        return [{ label: 'Opciones', items: items }];
    }

    const seleccionar = (data) => {
        values({
            fecha: data?.fecha,
            tipo_identificacion: data?.tipo_identificacion,
            identificacion: data?.identificacion,
            nombres: data?.razon_social,
            idp: data?.id,
            tipo: dialogBusqueda === true ? 'juridico' : 'natural',
        });
    }

    const regresar = () => {
        setMatriz(null);
        setDialogPerfil(false);
        loadLazyData();
    }

    return (
        <>
             <Toast ref={toast} />
            {!dialogPerfil && <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={startContent} end={generic.rightToolbarTemplate(registers, omodel, 'Listado_matrices', 'LISTADO MATRICES')}></Toolbar>
                {table({ action01: accionesTbl, reg: setMatriz, items: itemsMenu() })}
            </Card>}

            <Dialog visible={dialogCliente} header={getValues('id') == null ? "Nueva matriz" : "Editar matriz"} modal className="p-fluid modal-ssize"
                onHide={e => setDialogCliente(false)} style={{ maxWidth: '500px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inCalendar('fecha', {}, 12)}
                        {inDrop('tipo_identificacion', catalogo?.tipo_identificacion, { lab: omodel.optionLabel }, 6)}
                        {inText('identificacion', {}, 6)}
                        {inText('nombres', {}, 12)}
                    </div>
                    {generic.buttonsForms(setDialogCliente)}
                </form>
            </Dialog>

            <Dialog visible={dialogBusqueda} header='BÚSQUEDA - INFORMACIÓN DE PERSONA JURÍDICA: Proveedor' modal className="p-fluid modal-ssize"
                onHide={e => setDialogBusqueda(false)} style={{ maxWidth: '900px' }}>
                <Busqueda opcion="persona-juridica-proveedor-matriz-bvg" permss={permss} toast={toast} seleccion={seleccionar} />
            </Dialog>

            <Dialog visible={dialogNatural} header='BÚSQUEDA - INFORMACIÓN DE PERSONA NATURAL: Proveedor' modal className="p-fluid modal-ssize"
                onHide={e => setDialogNatural(false)} style={{ maxWidth: '900px' }}>
                <Busqueda opcion="persona-natural-proveedor-matriz-bvg" permss={permss} toast={toast} seleccion={seleccionar} />
            </Dialog>

            {dialogPerfil && <DetalleProveedor matrizId={matriz?.id} permss={permss} setDialogPerfil={setDialogPerfil} toast={toast} tipo={tipo} regresar={regresar} />}
        </>
    )
}
