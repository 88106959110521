export class Financiera {
    model = {
        INGR_MENSUAL_PROPIOS: {
            value: 0,
            export: false,
            header: 'Ingresos propios',
            required: 'Valor requerido'
        },
        INGR_MENSUAL_CONYUGUE: {
            value: 0,
            export: false,
            header: 'Ingresos Cónyuge',
            required: 'Valor requerido'
        },
        INGR_MENSUAL_OTROS: {
            value: 0,
            export: false,
            header: 'Otros Ingresos **',
            required: 'Valor requerido'
        },
        INGR_MENSUAL_TOTAL: {
            value: 0,
            export: false,
            header: 'TOTAL INGRESOS',
            required: 'Valor requerido'
        },
        EGRE_MENSUAL_ALIMENTACION: {
            value: 0,
            export: false,
            header: 'Alimentación',
            required: 'Valor requerido'
        },
        EGRE_MENSUAL_EDUCACION: {
            value: 0,
            export: false,
            header: 'Educación',
            required: 'Valor requerido'
        },
        EGRE_MENSUAL_VIVIENDA: {
            value: 0,
            export: false,
            header: 'Vivienda',
            required: 'Valor requerido'
        },
        EGRE_MENSUAL_SALUD: {
            value: 0,
            export: false,
            header: 'Salud',
            required: 'Valor requerido'
        },
        EGRE_MENSUAL_CREDITOS: {
            value: 0,
            export: false,
            header: 'Créditos',
            required: 'Préstamos hipotecarios es requerido'
        },
        EGRE_MENSUAL_OTROS: {
            value: 0,
            export: false,
            header: 'Otros Egresos ***',
            required: 'Valor requerido'
        },
        EGRE_MENSUAL_TOTAL: {
            value: 0,
            export: false,
            header: 'TOTAL EGRESOS',
            required: 'Valor requerido'
        },
        total_activos: {
            value: 0,
            export: false,
            header: '(A) ACTIVOS',
            required: 'Valor requerido'
        },
        total_pasivos: {
            value: 0,
            export: false,
            header: '(B) PASIVOS',
            required: 'Valor requerido'
        },
        total_patrimonio: {
            value: 0,
            export: false,
            header: '( C ) PATRIMONIO (A) - (B)',
            required: 'Otros pasivos es requerido'
        },
        declara: {
            value: null,
            export: false,
            header: 'Declara usted Impuesto a la Renta',
            required: 'Seleccione una opción'
        },
        detalle_otro_ingreso: {
            value: '',
            export: false,
            header: '** Especifique la fuente de "Otros Ingresos"',
            required: 'Campo requerido'
        },
        detalle_otro_egreso: {
            value: '',
            export: false,
            header: '*** Especifique el uso de "Otros Egresos"',
            required: 'Campo requerido'
        },
        detalle_act_economica: {
            value: '',
            export: false,
            header: <><b>a)</b> Por favor indique el origen de los fondos o recursos empleados en sus operaciones, provienen de:</>,
            required: 'Campo requerido'
        },
    }
    optionLabel = 'nombre';
    det1 = <><span style={{ fontSize: '9px' }}>(Inmuebles: Casa, departamento, terreno, etc.) (Muebles: Vehículos, Camión, etc.) (Otros Bienes Muebles: Obras de arte, joyas, colecciones, menaje de casa,
        equipo de oficina, semovientes, inventarios / mercaderías, Máquina Equipo) (Dinero en Efectivo o en Instituciones) (Inversiones) (Créditos por Cobrar).</span></>;
    det2 = <><span style={{ fontSize: '9px' }}>(Créditos: Hipotecarios, Prendario, personal, tarjeta de crédito, etc.)</span></>
    nota = <><span style={{ fontSize: '9px' }}><b>Nota:</b> La información financiera declarada en este formato, podrá cumplirse mediante la entrega de una copia legible de la Declaración Patrimonial
        Juramentada del último año fiscal. ( Activos, Pasivos y Patrimonio)</span></>
}
