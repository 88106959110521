import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { useForm } from "react-hook-form";
import { PlantillaCorreoService } from "../../service/admin/PlantillaCorreoService";
import { InputComponent } from "../../components/InputComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Generico } from "../../components/Generico";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Card } from "primereact/card";
import { PlantillaCorreo } from "../../models/administracion/PlantillaCorreo";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { EmpresaService } from "../../service/EmpresaService";

export const PlanCorreo = () => {
    const { accionesTbl } = Generico();
    const generic = new GenericComponent();
    const omodel = new PlantillaCorreo();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    const [registros, setRegistros] = useState(null);
    const plantillaService = new PlantillaCorreoService();
    const [plantilla, setPlantilla] = useState(null);
    const [contenido, setContenido] = useState(null);
    const [selectedEmp, setSelectedEmp] = useState(null);
    const empresaService = new EmpresaService();
    const [emp, setEmp] = useState(null);
    const toast = useRef(null);
    const [dialogPlantilla, setDialogPlantilla] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inAutocomplete, inText } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        let _lazyParams = lazyParams;

        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        plantillaService.getItem({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
            setTotalRecords(res.data.total);
            setRegistros(res.data.registros);
            setLoading(false);
            if (res.data.perss !== undefined) {
                setPermss(res.data.perss);
            }
        });
    }

    const openNew = () => {
        setPlantilla(null);
        setContenido(null);
        setDialogPlantilla(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        data.contenido = contenido;
        if (getValues('id') == null) {
            plantillaService.nuevo(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPlantilla(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            plantillaService.editar(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogPlantilla(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editPlantilla = () => {
        plantillaService.consultar(plantilla?.id).then(res => {
            const _plantilla = res.data.correo;
            setSelectedEmp(_plantilla.empresa);
            setContenido(_plantilla.contenido);

            reset(generic.renewValues(_plantilla, omodel.model));
            setDialogPlantilla(true);
        });
    };

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar la empresa?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminar()
        });
    }

    const eliminar = () => {
        plantillaService.eliminar(plantilla.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar) items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editPlantilla() } });
        if (permss?.eliminar) items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const searchEmp = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setEmp([...emp]);
            }
            else {
                empresaService.consultarEmpresaNombre(event.query.toLowerCase()).then(data => {
                    setEmp(data);
                });
            }
        });
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}></Toolbar>
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                {table({ action01: accionesTbl, reg: setPlantilla, items: itemsMenu() })}
            </Card>

            <Dialog visible={dialogPlantilla} header={getValues('id') == null ? "Nueva plantilla" : "Editar plantilla"} modal className="p-fluid modal-ssize" onHide={e => setDialogPlantilla(false)}
                style={{ maxWidth: '600px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 6)}
                        {inText('nemonico', {}, 6)}
                        {inText('asunto', {}, 12)}
                        {inAutocomplete('empresa', selectedEmp, setSelectedEmp, emp, searchEmp, { lab: omodel.optionLabel, noEsActividad: true, col: 12 })}
                        <div className="field col-12">
                            <Editor value={contenido} onTextChange={(e) => setContenido(e.htmlValue)} style={{ height: '320px' }} />
                        </div>
                    </div>
                    {generic.buttonsForms(setDialogPlantilla)}
                </form>
            </Dialog>
        </>
    )
}
