import { Panel } from 'primereact/panel';
import { Bienes } from '../../../models/fun/Bienes';
import { Credito } from './Credito';
import { Divider } from 'primereact/divider';
import { Generico } from '../../../components/Generico';
import { FuncionarioService } from '../../../service/ficha/FuncionarioService';
import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { FileBvg } from '../../../components/FileBvg';
import { FileComponent } from '../../../components/FileComponent';
import { Button } from 'primereact/button';

export const PasivosCuentas = ({ generic, setDialogCliente, id, toast, handlePrev, next, permss, catalogos, urls, estado, cancelar, reiniciar, tipo }) => {
    const omodel = new Bienes();
    const { buttonsFormsReports, funcMessageBlobError, downloadPdf } = Generico();
    const funSrv = new FuncionarioService();
    const [loadReport, setLoadReport] = useState(false);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [estadoForm, setEstadoForm] = useState(null);
    const { enviarFormularioPep } = FileComponent({ generic, toast });

    useEffect(() => {
        if (!estado) {
            editFicha();
        } else {
            setEstadoForm(estado);
        }
    }, [id]);

    const generarReporte = (opcion = true) => {
        setLoadReport(true);
        const url = 'funcionario-' + (opcion ? 'principal' : 'declaracion');
        funSrv.descargar(id, url, id).then(res => {
            const nombre = opcion ? '-Formulario-Declarante.pdf' : '-Formulario-declaracion.pdf';
            downloadPdf(res, id + nombre);
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const editFicha = () => {
        funSrv.consultar(id, 'funcionario', 'seccion7').then(res => {
            setEstadoForm(res.data.fun?.estado);
        });
    }

    const subirReporte = () => {
        setDialogSubir(true);
    }

    const enviar = () => {
        enviarFormularioPep(id, urls.uenviar, tipo, reiniciar);
    }

    return (
        <>
            <Panel header="DETALLE DE PASIVOS: (Resumen global de los rubros detallados a continuación)" className="panel-ficha">
                <Credito id={id} toast={toast} permss={permss} next={next} catalogos={catalogos} opcion="funcionario-cuenta-pagar" />
            </Panel>
            <br />

            <Panel header="DECLARACIÓN Y AUTORIZACIÓN" className="panel-ficha">
                <div className="formgrid grid">
                    <div className="field col-12" style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}>
                        {omodel.strBVG}
                    </div>

                    <div className="field col-5 h-2rem" style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '10px', verticalAlign: 'bottom', fontWeight: 'bold' }}>
                        <Divider />
                        <span>Firma del Colaborador</span>
                    </div>

                    <Divider />
                    {omodel.decBVG}

                    <Divider />
                    {omodel.procBVG}
                </div>
            </Panel>
            <br />
            <Panel header='LISTA DE COMPROBACIÓN DE INFORMACIÓN Y DOCUMENTOS ADJUNTOS AL FORMULARIO "INICIO DE RELACIÓN O ACTUALIZACIÓN" (CHECK LIST).' className="panel-ficha">
                <div className="formgrid grid">
                    <div className="field col-5">
                        {omodel.bgvAnexo}
                    </div>
                </div>
            </Panel>
            <br />
            <br />

            <div className="formgrid grid">
                <div className="field col-3">
                    <span style={{ fontStyle: 'italic', fontSize: '10px', fontWeight: 'bold' }}>*Descargar Declaración de Origen Lícito de Recursos</span><br />
                    <Button label="Anterior" type="button" icon="pi pi-chevron-left" onClick={e => { handlePrev(); }} />
                    &nbsp;
                    <Button label="Declaración*" icon="pi pi-print" type="button" className="p-button-warning" onClick={() => generarReporte(false)}
                        loading={loadReport} disabled={loadReport} title='Generar Declaración de Origen Lícito de Recursos en PDF' />
                </div>
                <div className="field col-9" style={{ textAlign: 'center' }}>
                    {buttonsFormsReports(setDialogCliente, generarReporte, subirReporte, enviar, loadReport, id != null, cancelar, false)}
                </div>
            </div>

            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo={tipo} urls={urls} idp={id} toast={toast} permss={permss} estado={estadoForm} reiniciar={reiniciar} setDialogSubir={setDialogSubir} />
            </Dialog>
        </>
    )
}
