import { saveAs } from "file-saver";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import XLSX from "xlsx-color";

export class GenericComponent {

    mascaraCedula = "9999999999";
    mascaraRuc = "9999999999999";

    EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    EXCEL_EXTENSION = ".xlsx";

    dateFormat = "dd/mm/yy";
    idioma = "es";
    currentDate = new Date();
    esFormat = {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    }

    estadosSiNo = [
        { nombre: 'SI', cat_id: true },
        { nombre: 'NO', cat_id: false }
    ];

    formatFecha = (fecha) => {
        return fecha.toISOString().split('T')[0];
    }

    paramLazy = (filters, vars) => {
        let params = {
            first: 0,
            rows: 10,
            page: 1,
            sortField: null,
            sortOrder: null
        }
        if (vars !== null && vars !== undefined) {
            Object.keys(vars).map((key, index) => {
                params[key] = vars[key];
                return 1;
            });
        }

        if (filters !== null) {
            let filts = {};
            Object.keys(filters).map((key, index) => {
                filts[key] = { value: '', matchMode: filters[key] };
                return 1;
            });
            params['filters'] = filts;
        }

        return params;
    }

    infoIdentificacion = (tipo) => {
        const strIden = tipo === 'ruc' ? 'Ruc' : 'Cédula';
        const lenIden = tipo === 'ruc' ? 13 : 10;
        let strTipo = {};
        strTipo['keyFilter'] = 'num';
        strTipo['minMesLen'] = strIden + ' requiere ' + lenIden + ' dígitos';
        strTipo['minValLen'] = lenIden;
        strTipo['maxValLen'] = lenIden;
        strTipo['maxMesLen'] = 'Requerido ' + lenIden + ' dígitos';

        return strTipo;
    }

    renewValues = (values, model) => {
        Object.keys(values).map((key) => {
            if (model[key] !== undefined && model[key].value !== undefined) {
                if (values[key] == null && model[key].value === '') {
                    values[key] = '';
                }
            }
            return 1;
        });

        return values;
    }

    stringToDate = (fecha) => {
        const [year, month, day] = fecha.split('-');
        return new Date(+year, parseInt(month) - 1, +day);
    }

    dateToString = (fecha) => {
        return fecha.toISOString().split('T')[0];
    }

    default = (model) => {
        let values = {};
        Object.keys(model).map((key) => {
            if (model[key].value !== undefined) {
                values[key] = model[key].value;
            }
            return 1;
        });

        return values;
    }

    addValues = (values, items) => {
        let info = { ...values };
        items.forEach(itm => {
            info[itm.nemonico] = { value: itm.valor == null ? 0 : itm.valor, export: false, header: itm.nombre, required: itm.nombre + ' es requerido.' };
        });

        return info;
    }

    downloadPdf = (res, fileName) => {
        const fileURL = window.URL.createObjectURL(
            new Blob([res])
        );
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);

        fileLink.click();
        document.body.removeChild(fileLink);
    }

    funcMessageBlobError = async (title, error, toast) => {
        try {
            let message = error.response.data.message;
            if (!message) {
                const responseObj = await error.response.data.text();
                error.response.data = JSON.parse(responseObj);
                message = error.response.data.message;
            }
            toast.current.show({ severity: 'error', summary: title, detail: message, life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: title, detail: 'Corrija los errores.', life: 3000 });
        }
    }

    downloadFileB64 = (tipo, res, fileName) => {
        const mime = tipo === 'pdf' ? 'application/pdf;base64' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const linkSource = `data:${mime};base64,${res}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        downloadLink.download = fileName + "." + tipo;
        document.body.appendChild(downloadLink);

        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    buttonsForms = (hideDialog, load = false) => {
        return (
            <React.Fragment>
                <div className="formgrid grid">
                    <div className="field col">
                        <Button label="Guardar" style={{ maxWidth: '120px', float: 'right' }} type="submit" icon="pi pi-check" className="p-button-warning" loading={load} title="Guardar" />
                    </div>
                    <div className="field col">
                        <Button label="Cancelar" style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" title="Cancelar" disabled={load} className="p-button-warning" onClick={() => hideDialog(false)} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    buttonsFormsReports = (hideDialog, generarReporte, subirReporte, enviar, loading, habilitar = false, cancelar = true, loadSave = false) => {
        return (
            <div className="formgrid grid">
                <div className="field col-12" style={{ textAlign: 'center' }}>
                    <Button label="Guardar" type="submit" icon="pi pi-save" className="p-button-warning" loading={loadSave} disabled={loadSave} />&nbsp;
                    {habilitar && <><Button label="Reporte" icon="pi pi-file-o" type="button" className="p-button-warning" onClick={() => generarReporte()}
                        loading={loading} disabled={loading} />&nbsp;</>}
                    {habilitar && <><Button label="Enviar" icon="pi pi-send" type="button" className="p-button-warning" onClick={() => enviar()} />&nbsp;</>}
                    {habilitar && <><Button label="Anexos" icon="pi pi-copy" type="button" className="p-button-warning" onClick={() => subirReporte()} />&nbsp;</>}
                    {cancelar && <><Button label="Cancelar" icon="pi pi-times" type="button" className="p-button-warning" onClick={() => hideDialog(false)} /></>}
                </div>
            </div>
        );
    }

    buttonsForms2 = (hideDialog, id, cancelar, regresar) => {
        return (
            <React.Fragment>
                <div className="formgrid grid">
                    <div className="field col">
                        <Button label="Guardar" style={{ maxWidth: '120px', float: 'right' }} type="submit" icon="pi pi-check" className="p-button-warning" />
                    </div>
                    <div className="field col">
                        <Button label={id == null ? cancelar : regresar} style={{ maxWidth: '120px' }} icon="pi pi-times" type="button" className="p-button-warning" onClick={() => hideDialog()} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    leftToolbarTemplate2 = (openNew, label1, btnRegresar, permss) => {
        return (
            <React.Fragment>
                <Button label={label1} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} style={{ display: permss?.crear ? 'block' : 'none' }} />
                {btnRegresar}
            </React.Fragment>
        );
    };

    getFormErrorMessage = (errors, name) => {
        return errors[name] && <small className="p-error"><br />{errors[name].message}</small>
    };

    estados = [
        { nombre: 'Activo', cat_id: true },
        { nombre: 'Inactivo', cat_id: false }
    ];

    estadosNum = [
        { nombre: 'Activo', cat_id: 1 },
        { nombre: 'Inactivo', cat_id: 0 }
    ];

    sino = [
        { nombre: 'SI', cat_id: true },
        { nombre: 'NO', cat_id: false }
    ];

    getNombreEstado = (estado) => {
        let strEstado = "Inactivo";
        this.estados.forEach(st => {
            if (estado === st.cat_id) {
                strEstado = st.nombre;
            }
        });

        return strEstado;
    }

    getItemEstado = (estado) => {
        let itemEstado = { nombre: "Inactivo", cat_id: false };
        this.estados.forEach(st => {
            if (estado === st.cat_id) {
                itemEstado = st;
            }
        });

        return itemEstado;
    }

    exportPdf = (fileName, model, items, title) => {
        const exportColumns = this.columnsExport(model);
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                doc.autoTable({
                    columns: exportColumns, margin: { top: 35 }, body: items, didDrawPage: function (data) {
                        doc.text(title, 20, 30);
                    }
                });
                doc.save(fileName);
            })
        });
    }

    exportFileExcel = (fileName, items) => {
        const ws = XLSX.utils.json_to_sheet(items);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: this.EXCEL_TYPE });
        saveAs(data, fileName + this.EXCEL_EXTENSION);
    }

    exportExcel = (fileName, items, model) => {
        const data = this.dataReportExcel(items, model);
        const ws = XLSX.utils.json_to_sheet(data);
        const cols = this.colsReportExcel(model);
        let widths = [];
        const lettler = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        cols.forEach((item, index) => {
            widths.push({ width: 30 });
            ws[lettler[index] + "1"].s = {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "2049D0" }
                },
                font: {
                    name: "Times New Roman",
                    sz: 13,
                    color: { rgb: "FFFFFF" },
                    bold: true,
                    italic: false,
                    underline: false
                }
            };
        });
        ws["!cols"] = widths;
        const wb = {
            Sheets: {
                data: ws
            },
            SheetNames: ["data"],
            Workbook: { Views: [{ RTL: false }] }
        };

        const eb = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([eb], { type: this.EXCEL_TYPE });
        saveAs(blob, fileName + this.EXCEL_EXTENSION);
    }

    columnsExport = (model) => {
        let columns = [];
        Object.keys(model.model).map((key) => {
            if (model.model[key].export) {
                let item = {};
                item.title = model.model[key].header;
                item.dataKey = key;
                columns.push(item);
            }
            return 1;
        });

        return columns;
    }

    cambiarLabelEstado = (data) => {
        data.forEach(item => {
            let _estado = item.estado;
            if(typeof _estado !== "string") {
                _estado = typeof item.estado == "object" ? this.getNombreEstado(item.estado) : (item.estado ? 'Activo' : 'Inactivo');
            }
            item.estado = _estado;
        });

        return data;
    }

    colsReportExcel = (model) => {
        let columns = [];
        Object.keys(model.model).map((key) => {
            if (model.model[key].export) {
                columns.push(model.model[key].header);
            }
            return 1;
        });

        return columns;
    }

    dataReportExcel = (data, model) => {
        let info = [];
        data.forEach(val => {
            let item = {};
            Object.keys(val).map((key) => {
                if (model.model[key] !== undefined && model.model[key].export) {
                    let valor = val[key];
                    if (model.model[key].sino !== undefined) {
                        valor = valor = val[key] ? 'Si' : 'No';
                    }
                    if (key === 'estado' && typeof val[key] !== "string") {
                        valor = typeof val[key] == "object" ? this.getNombreEstado(val[key]) : (val[key] ? 'Activo' : 'Inactivo');
                    } else if (valor === null) {
                        valor = '';
                    }
                    item[model.model[key].header] = valor;
                }
                return 1;
            });
            info.push(item);
        });

        return info;
    }

    funcSuccess = (res, toast) => {
        try {
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: res.message, life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Información registrada correctamente.', life: 3000 });
        }
    }

    funcMessage = (tipo, title, message, toast) => {
        toast.current.show({ severity: tipo, summary: title, detail: message, life: 3000 });
    }

    funcError = (error, toast, setError) => {
        try {
            const errs = error.response.data.errors;
            Object.keys(errs).map((key) => {
                let ikey = key;
                if (key.indexOf('.') >= 0) {
                    ikey = key.split('.');
                    ikey = ikey[0];
                }
                setError(ikey, { type: 'custom', message: errs[key] });
                return 1;
            });
        } catch (error) { }

        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Corrija los errores para guardar la información.', life: 3000 });
    }

    funcDeleteError = (error, toast) => {
        try {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error.response.data.message, life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al eliminar la información.', life: 3000 });
        }
    }

    rightToolbarTemplate = (items, omodel, titleExcel, titlePdf) => {
        return (
            <React.Fragment>
                <Button type="button" title='Exportar a Excel' icon="pi pi-file-excel" onClick={() => { if (items !== undefined && items.length > 0) { this.exportExcel(titleExcel, items, omodel) } }}
                    className="p-button-success mr-2" data-pr-tooltip="XLS" />
                <Button onClick={() => { if (items !== undefined) { this.exportPdf('Listado.pdf', omodel, this.cambiarLabelEstado([...items]), titlePdf) } }}
                    className="p-button-warning mr-2" data-pr-tooltip="PDF" type="button" title='Exportar a PDF' icon="pi pi-file-pdf" />
            </React.Fragment>
        );
    };

    leftToolbarTemplate = (openNew, label, permss) => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={label} icon="pi pi-plus" className="p-button-success mr-2" type="button" onClick={openNew} style={{ display: permss?.crear ? 'block' : 'none' }} />
                </div>
            </React.Fragment>
        );
    };

    actionEditTemplate = (rowData, edicion, permss, confirmDelete) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" style={{ display: permss?.actualizar ? 'block' : 'none' }} className="p-button-rounded p-button-success mr-2" type="button" onClick={() => edicion(rowData)} />
                <Button icon="pi pi-trash" style={{ display: permss?.eliminar ? 'block' : 'none' }} className="p-button-rounded p-button-warning mt-2" type="button" onClick={() => confirmDelete(rowData)} />
            </div>
        );
    };

    actionEditAddTemplate = (rowData, edicion, permss, confirmDelete, add) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" title="Editar accionista" style={{ display: permss?.actualizar ? 'block' : 'none' }} className="p-button-rounded p-button-success mr-2" type="button" onClick={() => edicion(rowData)} />
                {rowData.activado && <Button title="Consignar accionista" icon="pi pi-users" style={{ display: permss?.actualizar ? 'block' : 'none' }} className="p-button-rounded p-button-success mr-2" type="button" onClick={(e) => add(e, rowData.id)} />}
                <Button icon="pi pi-trash" title="Eliminar accionista" style={{ display: permss?.eliminar ? 'block' : 'none' }} className="p-button-rounded p-button-warning mt-2" type="button" onClick={() => confirmDelete(rowData)} />
            </div>
        );
    };

    actionEditDeleteTemplate = (rowData, edicion, permss, confirmDelete) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => edicion(rowData)} title="Editar" />&nbsp;
                <Button icon="pi pi-trash" style={{ display: permss?.eliminar ? 'block' : 'none' }} className="p-button-rounded p-button-warning mt-2" title="Eliminar" onClick={() => confirmDelete(rowData)} />
            </div>
        );
    };

    actionEditDetailTemplate = (rowData, edicion, icono, details, permss, confirmDelete, load = false, idv = 0) => {
        return (
            <div className="actions">
                {permss?.actualizar && (!rowData?.nemonico || rowData.nemonico !== 'FINALIZADO') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => edicion(rowData)} title={permss?.b1t ?? 'Editar'} />}
                <Button icon={icono} className="p-button-rounded p-button-success mr-2" onClick={() => details(rowData)} loading={load && idv === rowData.id} title={permss?.b2t ?? 'Detalles'} />
                {permss?.eliminar && (!rowData?.nemonico || rowData.nemonico !== 'FINALIZADO') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDelete(rowData)} title={permss?.b3t ?? 'Eliminar'} />}
            </div>
        );
    };

    actionEditDetailTemplateExtra = (rowData, edicion, icono, details, permss, confirmDelete, iconExtra, methExtra, load = false, idv = 0) => {
        return (
            <div className="actions">
                {permss?.actualizar && (!rowData?.nemonico || rowData.nemonico !== 'FINALIZADO') &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => edicion(rowData)} title="Editar" />}
                <Button icon={icono} className="p-button-rounded p-button-success mr-2" onClick={() => details(rowData)} loading={load && idv === rowData.id} />
                <Button icon={iconExtra} className="p-button-rounded p-button-success mr-2" onClick={() => methExtra(rowData)} />
                {permss?.eliminar && (!rowData?.nemonico || rowData.nemonico !== 'FINALIZADO') &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDelete(rowData)} title="Eliminar" />}
            </div>
        );
    };

    deleteDialogFooter = (setDialog, deleteRow) => {
        return (
            <>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => { setDialog(false) }} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRow() }} />
            </>
        )
    };

    dialogDelete = (dialogDelete, setDialogDelete, deleteRecord, rowData, message) => {
        return (
            <Dialog visible={dialogDelete} style={{ width: '450px' }} header="Confirmación" modal footer={this.deleteDialogFooter(setDialogDelete, deleteRecord)} onHide={e => { setDialogDelete(false) }}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {rowData?.id && (
                        <span>
                            {message}
                        </span>
                    )}
                </div>
            </Dialog>
        )
    }
}
