import { FilterMatchMode } from "primereact/api"

export class PlantillaCorreo {

    model = {
        id: { value: null, export: false },
        nombre: {
            value: '', export: true, header: 'Nombre', required: 'Nombre es requerido', maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres',
            max: 300, min: 4, filterField: 'nombre', table: true, filter: true, sort: true, filterPlaceholder: 'Buscar por nombre.', matchMode: FilterMatchMode.CONTAINS
        },
        asunto: {
            value: '', export: true, header: 'Asunto', required: 'Asunto es requerido', maxLength: 'Máximo 300 caracteres', minLength: 'Mínimo 4 caracteres',
            max: 300, min: 4
        },
        nemonico: {
            value: '', export: true, header: 'Nemónico', required: 'Nemónico es requerido', maxLength: 'Máximo 150 caracteres', minLength: 'Mínimo 4 caracteres',
            max: 150, min: 4
        },
        empresa: { value: null, export: false, header: 'Empresa', required: 'Empresa es requerido', table: true, filter: true, sort: true },
        contenido: { value: '', export: true, header: 'Contenido', required: 'Contenido es requerido', minLength: 'Mínimo 4 caracteres', min: 4 },
    }

    optionLabel = 'nombre'
}
