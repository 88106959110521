import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { addLocale } from "primereact/api";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { PersonaNaturalService } from "../../../service/personaNatural/PersonaNaturalService";
import { ClientePn } from "../../../models/ClientePn";
import { GenericComponent } from "../../../components/GenericComponent";
import { InputComponent } from "../../../components/InputComponent";
import { useToken } from "../../../components/useToken";
import { FileComponent } from "../../../components/FileComponent";
import { DatosGenerales } from "./DatosGenerales";
import { Divider } from "primereact/divider";
import { InformacionLaboral } from "./InformacionLaboral";
import { InfFinanciera } from "./InfFinanciera";
import { Ampliada } from "./Ampliada";
import { FormPep } from "../FormPep";
import { Dialog } from "primereact/dialog";
import { FileBvg } from "../../../components/FileBvg";
import { Generico } from "../../../components/Generico";

export const PersonaNatural = () => {

    const generic = new GenericComponent();
    const { accionesTbl } = Generico();
    const omodel = new ClientePn();
    let loadLazyTimeout = null;
    const [clientespn, setClientespn] = useState([]);
    const clientepnSrv = new PersonaNaturalService();
    const toast = useRef(null);
    const [dialogClientepn, setDialogClientepn] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [cliente, setCliente] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [permss, setPermss] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams } = InputComponent({ registers: clientespn, omodel, generic, permss, id: cliente?.id });
    const { getPerss, getRol } = useToken();
    const [siCliente, setSiCliente] = useState(false);
    const [urls, setUrls] = useState();
    const [urlsPep, setUrlsPep] = useState();
    const [clienteId, setClienteId] = useState(null);
    const [dialogSubir, setDialogSubir] = useState(false);
    const [dialogSubirPep, setDialogSubirPep] = useState(false);
    const [hayPep, setHayPep] = useState(false);
    const [pepBorrador, setPepBorrador] = useState(null);

    const { dialogInstruccion, setdialogIns } = FileComponent({ generic, tipo: 'clientePn', toast, permss, id: cliente?.id });

    addLocale(generic.idioma, generic.esFormat);

    useEffect(() => {
        setUrls({
            urlListado: 'ficha/persona-natural',
            urlDownload: 'ficha/persona-natural/descarga',
            urlGenerar: 'ficha/persona-natural/generar-consulta',
            urlUpload: 'ficha/persona-natural/store',
            udelete: 'ficha/persona-natural/eliminar-anexo',
            uactual: 'ficha/persona-natural/editar',
            uenviar: 'ficha/persona-natural/enviar',
            uaprobar: 'ficha/persona-natural/aprobar'
        });
        setUrlsPep({
            urlListado: 'ficha/pep-ficha',
            urlDownload: 'ficha/pep-ficha/descarga',
            urlUpload: 'ficha/pep-ficha/store',
            urlVerificacion: 'ficha/pep-ficha',
            udelete: 'ficha/pep-ficha/eliminar-anexo',
            uactual: 'ficha/pep-ficha/editar',
            uenviar: 'ficha/pep-ficha/enviar',
            uaprobar: 'ficha/pep-ficha/aprobar',
            urlGenerar: 'ficha/pep-ficha/generar-consulta',
        });
        setSiCliente(getPerss() !== undefined);

        if (getPerss() === undefined) {
            loadLazyData();
        }
        else {
            setPermss(getPerss());
            editFicha(null);//editFicha({ id: 0 });
        }
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            if (getRol()) {
                _lazyParams.rol = getRol();
            }
            clientepnSrv.getClientepn({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setClientespn(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
                if (res.data.cats) {
                    setCatalogos(res.data.cats);
                }
            });
        });
    }

    const openNew = () => {
        setDialogClientepn(true);
        setCliente(null);
        setdialogIns(true);
    }

    const editFicha = () => {
        if (catalogos === null) {
            clientepnSrv.newClientepn({ iniciar: 1 }).then(res => {
                setCatalogos(res.data.cats);
                setClienteId(res.data.clienteId ?? null);
                setDialogClientepn(true);
                setCliente(null);
                setActiveIndex(0);
                setdialogIns(true);
                if (res.data.pep !== undefined) {
                    setHayPep(res.data.pep);
                }
                if (res.data.pestado !== undefined) {
                    setPepBorrador(res.data.pestado);
                }
            });
        }
        else {
            setDialogClientepn(true);
            setCliente(cliente);
            setActiveIndex(0);
            setdialogIns(true);
            if (cliente && cliente?.pep !== undefined) {
                setHayPep(cliente.pep);
            }
        }
    };

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar el formulario?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteCliente()
        });
    }

    const deleteCliente = () => {
        clientepnSrv.eliminarClientepn(cliente?.id).then(res => {
            if (!siCliente) {
                loadLazyData();
            }
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const nuevoCliente = (nuevo) => {
        const _cliente = cliente ?? {};
        if (nuevo) {
            if (nuevo?.id !== undefined) _cliente.id = nuevo.id;
            if (nuevo?.pep !== undefined) _cliente.pep = nuevo.pep;
            if (nuevo?.nemonico !== undefined) _cliente.nemonico = nuevo.nemonico;
            if (nuevo?.nombre_completo !== undefined) _cliente.nombre_completo = nuevo.nombre_completo;
            if (nuevo?.identificacion !== undefined) _cliente.identificacion = nuevo.identificacion;
            if (nuevo?.pepBorrador !== undefined) setPepBorrador(nuevo.pepBorrador);
        }
        setHayPep(_cliente.pep === true || _cliente.pep > 0);
        setCliente(_cliente);
    }

    const regresar = () => {
        setActiveIndex(0);
        setDialogClientepn(false);
        if (!siCliente) {
            loadLazyData();
        }
    }

    const handleNext = () => {
        if (activeIndex < 7) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const handlePrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const nextPrev = (btn = true) => {
        const btnNext = btn ? <Button label="Siguiente" type="submit" icon="pi pi-chevron-right" /> :
            <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => { handleNext(); }} />;
        return (
            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />
                </div>
                <div className="field col">
                    {btnNext}
                </div>
            </div>
        )
    }

    const archivos = () => {
        setDialogSubir(true);
    }

    const reiniciar = () => {
        if (!siCliente) {
            loadLazyData();
        }
        setDialogClientepn(false);
        setDialogSubirPep(false);
        setDialogSubir(false);
    }

    const reiniciarPep = (ban) => {
        setActiveIndex(3);
        if(ban && ban === 'ENVIADO') {
            setPepBorrador('ENVIADO');
        }
    }

    const archivosPep = () => {
        setDialogSubirPep(true);
    }

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar && (!cliente?.nemonico || cliente.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editFicha() } });
        if (permss?.consultar) items.push({ label: 'Anexos formulario', icon: 'pi pi-clone', command: () => { archivos() } });
        if (permss?.consultar && cliente?.pep !== null) items.push({ label: 'Anexos formulario(PEP)', icon: 'pi pi-reddit', command: () => { archivosPep() } });
        if (permss?.eliminar && (!cliente?.nemonico || cliente.nemonico !== 'FINALIZADO'))
            items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            {!dialogClientepn && !siCliente && <>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(clientespn, omodel, 'Reporte_persona_natural', 'LISTADO PERSONA NATURAL')}></Toolbar>
                <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                    {table({ action01: accionesTbl, reg: setCliente, items: itemsMenu() })}
                </Card></>
            }
            {dialogClientepn &&
                <Panel header="INFORMACIÓN DE PERSONAS NATURALES" style={{ maxWidth: '100%', margin: 'auto' }}>
                    {omodel.titleBVG}
                    <Divider />
                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Sección 1">
                            <DatosGenerales id={cliente?.id ?? clienteId} siCliente={siCliente ?? clienteId} next={handleNext} permss={permss} catalogos={catalogos} nuevoCliente={nuevoCliente}
                                toast={toast} seccion="seccion1" />
                        </TabPanel>

                        <TabPanel header="Sección 2" disabled={cliente == null}>
                            <InformacionLaboral generic={generic} nextPrev={nextPrev} next={handleNext} permss={permss} cliente={cliente} catalogos={catalogos} toast={toast}
                                seccion="seccion2" nuevoCliente={nuevoCliente} />
                        </TabPanel>

                        <TabPanel header="Sección 3" disabled={cliente == null}>
                            <InfFinanciera generic={generic} id={cliente?.id ?? clienteId} permss={permss} nextPrev={nextPrev} next={handleNext} catalogos={catalogos} toast={toast} seccion="seccion3" />
                        </TabPanel>

                        <TabPanel header="Sección 4 (final)" disabled={cliente == null}>
                            <Ampliada generic={generic} id={cliente?.id ?? clienteId} permss={permss} nextPrev={nextPrev} next={handleNext} catalogos={catalogos} toast={toast}
                                setDialogClientepn={setDialogClientepn} seccion="seccion4" tipo='clientePn' cancelar={getPerss() === undefined} urls={urls} estado={cliente?.nemonico}
                                reiniciar={reiniciar} handlePrev={handlePrev} />
                        </TabPanel>

                        {hayPep && <TabPanel header={pepBorrador != null ? "Formulario PEP Receptado" : "Sección PEP"} disabled={cliente == null || !cliente?.id || pepBorrador != null}>
                            <FormPep tipo="clipn" idp={cliente?.id ?? clienteId} idOrigen={null} permss={permss} toast={toast} urls={urls} reiniciar={reiniciarPep} setDialogClientepn={setDialogClientepn} />
                        </TabPanel>}
                    </TabView>
                </Panel>
            }
            <br />

            {dialogClientepn && !siCliente && <Button label="Regresar" icon="pi pi-replay" onClick={regresar} />}
            {dialogInstruccion(omodel.titulo, omodel.mensajeBVG)}

            <Dialog visible={dialogSubir} header={"Gestión de anexos: " + cliente?.nombre_completo} modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo="clipn" urls={urls} idp={cliente?.id} toast={toast} permss={permss} estado={cliente?.nemonico} reiniciar={reiniciar} setDialogSubir={setDialogSubir} />
            </Dialog>

            <Dialog visible={dialogSubirPep} header={"Gestión de anexos (PEP): " + cliente?.nombre_completo} modal className="p-fluid modal-ssize" onHide={e => setDialogSubirPep(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo="clipn" urls={urlsPep} idp={cliente?.iep} toast={toast} permss={permss} estado={cliente?.estado_pep} reiniciar={reiniciar} setDialogSubir={setDialogSubirPep} />
            </Dialog>
        </>
    )
}
