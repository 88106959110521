export class AccionistaSocio {

    model = {
        idas: {
            value: null,
            export: false
        },
        tipo_accionista_socio: {
            value: null,
            export: false,
            header: 'Tipo Accionista Socio',
            required: 'Tipo Accionista Socio es obligatorio',
            table: true,
            filter: false,
            sort: true
        },
        tipo_identificacion: {
            value: null,
            export: false,
            filterPlaceholder: 'Buscar por tipo de identificación',
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
            table: true,
            filter: false,
            sort: true
        },
        identificacion: {
            value: '',
            export: false,
            header: 'Identificación',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por identificación',
            table: true,
            filter: false,
            sort: true
        },
        nombres: {
            value: '',
            export: true,
            header: 'Nombres',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4
        },
        apellidos: {
            value: '',
            export: true,
            header: 'Apellidos',
            required: 'Apellidos es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4
        },
        razon_social: {
            value: '',
            export: true,
            header: 'Razón Social',
            required: 'Razón Social es requerido',
            maxLength: 'Máximo 400 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 400,
            min: 4
        },
        actividad_economica: {
            value: null,
            export: false,
            header: 'Actividad Economica',
            required: 'Actividad Economica es requerido',
            header: 'Actividad Economica',
            table: true,
            filter: false,
            sort: true
        },

        nombre_completo: {
            value: '',
            export: false,
            header: 'Razón social/Nombres y Apellidos',
            table: true,
            filter: false,
            sort: true
        },
        nacionalidad: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: ' Nacionalidad es Requerida',
        },
        cargo: {
            value: '',
            export: false,
            header: 'Cargo:',
            required: 'Cargo es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4,
        },
        socio: {
            value: '',
            export: true,
            header: 'Socio/Acccionista',
            table: true
        }
    }

    optionLabel = 'nombre';
}
