import React, { useEffect, useState } from 'react';
import { Panel } from 'primereact/panel';
import { CapitalBvg } from '../../../models/cpj/CapitalBvg';
import { useForm } from 'react-hook-form';
import { PersonaJuridicaService } from '../../../service/personaNatural/PersonaJuridicaService';
import { InputComponent } from '../../../components/InputComponent';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

export const FinancieraPepBVG = ({ toast, id, permss, generic, handleNext, handlePrev, seccion, cliente, nuevoCliente }) => {
    const omodel = new CapitalBvg();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues, setValue, trigger } = useForm({ defaultValues, shouldTrim: true });
    const juridicaSrv = new PersonaJuridicaService();
    const { inNumber, inText, inDrop } = InputComponent({ omodel, generic, errors, control, permss, id: getValues('id') });

    const changePep = (e) => {
        const pep1 = getValues('si_pep') === null ? false : getValues('si_pep').cat_id;
        nuevoCliente({ pep: pep1 });
    }

    const [hayPep, setHayPep] = useState({ lab: omodel.optionLabel, chn: changePep });
    useEffect(() => {
        editFicha();
    }, [id]);

    const editFicha = () => {
        juridicaSrv.consultarCliente(id, seccion).then(res => {
            const lista = {
                si_pep: generic.sino,
                si_pep_familia: generic.sino,
                tiene_politicas: generic.sino,
                regulado_uafe: generic.sino
            }
            const _dec = res.data.clientepj;
            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            const _hayPep = { lab: omodel.optionLabel, chn: changePep };
            if (_dec.pip) {
                _hayPep.ne = true;
            }
            setHayPep(_hayPep);
            reset(generic.renewValues(_dec, omodel.model));
        });
    }

    const onSubmit = (data) => {
        data.id = id;
        data.seccion = seccion;
        juridicaSrv.editarCliente(data).then(res => {
            handleNext();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const actualizarPatrimonio = (e) => {
        const patrimonio = parseFloat(getValues('total_activos') ?? 0) - parseFloat(getValues('total_pasivos') ?? 0);
        setValue('patrimonio', patrimonio);
        trigger('patrimonio');
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Panel header="5.- Información Financiera" className="panel-ficha">

                    <div className="formgrid grid">
                        {inText('fuente_ingresos', {}, 9)}
                        <div className='col-3'></div>

                        {inNumber('total_activos', 2, true, { func: actualizarPatrimonio }, 4)}
                        <div className='col-1'></div>
                        {inNumber('ingresos_mensuales', 2, true, {}, 4)}
                        <div className='col-3'></div>

                        {inNumber('total_pasivos', 2, true, { func: actualizarPatrimonio }, 4)}
                        <div className='col-1'></div>
                        {inNumber('egresos_mensuales', 2, true, {}, 4)}
                        <div className='col-3'></div>

                        {inNumber('patrimonio', 2, true, { disabled: true, min: 'nulo' }, 4)}
                        <div className='col-1'></div>
                    </div>

                </Panel>
                <br />

                <Panel header="6.- Información Legal" className="panel-ficha">
                    <div className="formgrid grid">
                        {inDrop('regulado_uafe', generic.sino, { lab: omodel.optionLabel }, 5)}
                        <div className='col-4'>De ser afirmativo, adjuntar Certificado de Cumplimiento actualizado</div>
                        <div className='col-3'></div>

                        {inDrop('tiene_politicas', generic.sino, { lab: omodel.optionLabel }, 5)}
                    </div>

                </Panel>
                <br />

                <Panel header="7.- Persona Expuesta Políticamente o Públicamente (PEP´s)." className="panel-ficha">
                    <div className="formgrid grid">
                        <div className='col-12'>{omodel.cab1}</div>

                        <div className='col-4'>
                            <span style={{ fontSize: '10px', alignContent: 'justify' }}>
                                Yo, <b>{cliente?.representante}</b> representante legal de la compañía <b>{cliente?.razon_social}</b> y declaro bajo juramento que
                            </span>
                        </div>
                        {inDrop('si_pep', generic.sino, hayPep, 1)}
                        <div className='col-5'>
                            <span style={{ fontSize: '10px', alignContent: 'justify' }}>
                                me encuentro ejerciendo uno de los cargos, o tengo una relación de las citadas en el párrafo anterior de esta sección.
                            </span>
                        </div>

                        <div className='col-4'>
                            <span style={{ fontSize: '10px', alignContent: 'justify' }}>
                                Además declaro que los socios y accionistas, apoderado, firmas autorizadas o sus familiares dentro o fuera del país.
                            </span>
                        </div>
                        {inDrop('si_pep_familia', generic.sino, { lab: omodel.optionLabel }, 1)}
                        <div className='col-5'>
                            <span style={{ fontSize: '10px', alignContent: 'justify' }}>
                                desempeñan o han desempeñado funciones en el sector público, en los últimos 4 años.
                            </span>
                        </div>

                        <div className='col-12'>{omodel.cab2}</div>
                        <Divider />
                        <div className='col-12'>{omodel.cab3}</div>
                    </div>
                </Panel>
                <br />

                <div className="formgrid grid">
                    <div className="field col">
                        <Button label="Anterior" icon="pi pi-chevron-left" type="submit" onClick={e => handlePrev()} />&nbsp;
                        <Button label="Siguiente" type="submit" icon="pi pi-chevron-right" />
                    </div>
                </div>
            </form>
        </>
    );
}
