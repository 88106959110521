import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { ConfiguracionArchivo } from "../../models/administracion/ConfiguracionArchivo";
import { useForm } from "react-hook-form";
import { ConfiguracionArchivoService } from "../../service/admin/ConfiguracionArchivoService";
import { InputComponent } from "../../components/InputComponent";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Generico } from "../../components/Generico";
import { RolArchivo } from "./RolArchivo";

export const ConfigArchivo = () => {
    const generic = new GenericComponent();
    const omodel = new ConfiguracionArchivo();
    const { accionesTbl } = Generico();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    const [registros, setRegistros] = useState(null);
    const archivoSrv = new ConfiguracionArchivoService();
    const toast = useRef(null);
    const [dialogArchivo, setDialogArchivo] = useState(false);
    const [catalogos, setCatalogos] = useState(null);
    const [archivo, setArchivo] = useState(null);
    const [dialogRol, setDialogRol] = useState(false);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText, inNumber } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);
        let _lazyParams = lazyParams;
        delete _lazyParams.rtipo;
        if (permss == null) {
            _lazyParams.rtipo = true;
        }
        archivoSrv.getArchivo({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
            setTotalRecords(res.data.total);
            setRegistros(res.data.registros);
            setLoading(false);
            if (res.data.perss !== undefined) {
                setPermss(res.data.perss);
                setCatalogos(res.data.catalogos);
            }
        });
    }

    const openNew = () => {
        setDialogArchivo(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            archivoSrv.newArchivo(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogArchivo(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            archivoSrv.editarArchivo(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogArchivo(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editArchivo = () => {
        archivoSrv.consultarArchivo(archivo?.id).then(res => {
            let cats = catalogos;
            let _archivo = res.data;
            const lista = { tipo_formulario: cats.tipo_formulario, tipo_adunto: cats.tipo_adunto, estado: generic.estados, es_revision: generic.sino, obligatorio: generic.sino };

            Object.keys(lista).forEach(function (key) {
                if (_archivo[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _archivo[key]) {
                            _archivo[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_archivo, omodel.model));
            setDialogArchivo(true);
        });
    };

    const confirmDelete = () => {
        confirmDialog({
            message: '¿Está seguro de eliminar?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteArchivo()
        });
    }

    const deleteArchivo = () => {
        archivoSrv.eliminarArchivo(archivo?.id).then(res => {
            generic.funcSuccess(res, toast);
            loadLazyData();
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    };

    const itemsMenu = () => {
        const items = [];
        if (permss?.actualizar) items.push({ label: 'Editar', icon: 'pi pi-pencil', command: () => { editArchivo() } });
        if (permss?.actualizar) items.push({ label: 'Asignar rol', icon: 'pi pi-key', command: () => { setDialogRol(true) } });
        if (permss?.eliminar) items.push({ label: 'Eliminar', icon: 'pi pi-trash', command: () => { confirmDelete() } });

        return [{ label: 'Opciones', items: items }];
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise la información ingresada y proceda a corregir.', life: 3000 });
    }

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}></Toolbar>
                {table({ action01: accionesTbl, reg: setArchivo, items: itemsMenu() })}
            </Card>

            <Dialog visible={dialogArchivo} header={getValues('id') == null ? "Nueva configuración" : "Editar configuración"} modal className="p-fluid modal-ssize" onHide={e => setDialogArchivo(false)}
                style={{ maxWidth: '600px' }}>
                <br />
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 12)}
                        {inDrop('tipo_formulario', catalogos?.tipo_formulario, { lab: omodel.optionLabel }, 6)}
                        {inDrop('tipo_adjunto', catalogos?.tipo_adjunto, { lab: omodel.optionLabel }, 6)}
                        {inNumber('orden', 0, false, { min: 0 }, 6)}
                        {inDrop('obligatorio', generic.sino, { lab: omodel.optionLabel }, 6)}
                        {inDrop('es_revision', generic.sino, { lab: omodel.optionLabel }, 6)}
                        {inDrop('estado', generic.estados, { lab: omodel.optionLabel }, 6)}
                        {inText('nemonicos_obligatorio', {}, 6)}
                        {inText('extension_valida', {}, 6)}
                    </div>

                    {generic.buttonsForms(setDialogArchivo)}
                </form>
            </Dialog>

            <Dialog visible={dialogRol} header={"Asignar rol al archivo: " + archivo?.nombre} modal className="p-fluid modal-ssize" onHide={e => setDialogRol(false)} style={{ maxWidth: '800px' }}>
                <RolArchivo id={archivo?.id} toast={toast} permss={permss} generic={generic} />
            </Dialog>
        </>
    )
}
