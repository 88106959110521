import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Generico } from '../../../components/Generico';
import { FuncionarioService } from '../../../service/ficha/FuncionarioService';
import { Laboral } from '../../../models/fun/Laboral';
import { Divider } from 'primereact/divider';

export const InformacionLaboral = ({ generic, toast, id, permss, catalogos, handlePrev, handleNext, cliente, nuevoCliente }) => {

    const omodel = new Laboral();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    const funSrv = new FuncionarioService();
    const formRef = useRef(null);
    const [aplica, setAplica] = useState(false);
    const { inDrop, inText, inCalendar } = InputComponent({ omodel, generic, errors, control, permss, id: getValues('id') });
    const { stringToDate } = Generico();
    const onChangePep = (e) => {
        nuevoCliente({ pep: e.value?.cat_id });
    }
    const [hayPep, setHayPep] = useState({ lab: omodel.optionLabel, chn: onChangePep });

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.id = id;
        data.seccion = "seccion3";
        if (aplica) {
            data.inicio_actividad = data.inicio_actividad.toISOString().split('T')[0];
            data.fin_actividad = data.fin_actividad.toISOString().split('T')[0];
        }
        data.ant_na = catalogos?.ant_na;
        funSrv.editar(data, 'funcionario').then(res => {
            handleNext();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcError(error, toast, setError);
        });
    }

    const editFicha = () => {
        funSrv.consultar(id, 'funcionario', 'seccion3').then(res => {
            const _cat = catalogos;
            const _dec = res.data.fun;
            if (!_dec) {
                reset(defaultValues);
                return;
            }
            const lista = {
                laboral: _cat.relacion_laboral,
                relacion_pep: generic.sino
            };
            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            setAplica(JSON.stringify(_dec.laboral) !== JSON.stringify(_cat.ant_na));
            if (JSON.stringify(_dec.laboral) !== JSON.stringify(_cat.ant_na)) {
                if (_dec.inicio_actividad) _dec.inicio_actividad = stringToDate(_dec.inicio_actividad);
                if (_dec.fin_actividad) _dec.fin_actividad = stringToDate(_dec.fin_actividad);
            }

            const _pep = { lab: omodel.optionLabel, chn: onChangePep };
            if (_dec?.estado_pep) {
                _pep.ne = true;
            }
            setHayPep(_pep);

            reset(generic.renewValues(_dec, omodel.model));
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const siguiente = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(
                new Event('submit', { bubbles: true, cancelable: true })
            );
        }
    };

    const onChangeLaboral = (e) => {
        setAplica(JSON.stringify(e.value) !== JSON.stringify(catalogos.ant_na));
    }

    return (
        <>
            <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
                <Panel header="3.- Antecedente Laboral:" className="panel-ficha">
                    <div className="formgrid grid">
                        {inDrop('laboral', catalogos?.relacion_laboral, { lab: omodel.optionLabel, chn: onChangeLaboral })}
                        {aplica && inText('cargo')}
                        {aplica && inCalendar('inicio_actividad')}
                        {aplica && inCalendar('fin_actividad')}

                        {aplica && inText('area')}
                        {aplica && inText('ciudad_laboral')}
                    </div>
                </Panel>
                <br />

                <Panel header="4.- Persona Expuesta Políticamente o Públicamente (PEP´s)." className="panel-ficha">
                    {omodel.labelPep}
                    <div className="formgrid grid">
                        <div className="field col-12">
                            Yo, <b>{cliente?.nombre_completo}</b>, con documento de identificación No. <b>{cliente?.identificacion}</b>, he leído las definiciones de “Personas Expuestas Políticamente (PEP’s)”,
                        </div>
                        {inDrop('relacion_pep', generic.estadosSiNo, hayPep, 2)}
                        <div className="field col-10">
                            {omodel.dec2}
                        </div>
                    </div>
                    {omodel.dec3}
                    <Divider />
                    {omodel.dec4}
                </Panel>
            </form>
            <br />

            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />&nbsp;
                    <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => siguiente()} />
                </div>
            </div>
        </>
    )
}
