import { ConfirmDialog } from "primereact/confirmdialog"
import { Proveedor } from "./Proveedor"

export const MatrizProveedor = () => {
    return (
        <>
            <ConfirmDialog />
            <Proveedor tipo="proveedor" />
        </>
    )
}
