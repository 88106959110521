export class CapitalBvg {
    model = {
        id: { value: null, export: false },
        capital_suscrito: {
            value: 0,
            header: 'Capital Suscrito',
            required: 'Valor requerido',
            maxLength: 'valor máximo superado',
            minLength: 'Valor mínimo es 0',
            max: 9999999999999,
            min: 0,
        },
        capital_autorizado: {
            value: 0,
            header: 'Capital Autorizado',
            required: 'Valor requerido',
            maxLength: 'valor máximo superado',
            minLength: 'Valor mínimo es 0',
            max: 9999999999999,
            min: 0,
        },
        participacion: {
            value: 0,
            header: 'Acción/Participación (USD)',
            required: 'Valor requerido',
            maxLength: 'valor máximo superado',
            minLength: 'Valor mínimo es 0',
            max: 9999999999999,
            min: 0,
        },
        total_activos: {
            value: 0,
            header: '(A) Total Activos:',
            required: 'Valor requerido',
            maxLength: 'valor máximo superado',
            minLength: 'Valor mínimo es 0',
            max: 9999999999999,
            min: 0,
        },
        total_pasivos: {
            value: 0,
            header: '(B) Total de Pasivos:',
            required: 'Valor requerido',
            maxLength: 'valor máximo superado',
            minLength: 'Valor mínimo es 0',
            max: 9999999999999,
            min: 0,
        },
        patrimonio: {
            value: 0,
            header: '(A) - (B) Total Patrimonio:',
            required: 'Valor requerido',
            maxLength: 'valor máximo superado',
            minLength: 'Valor mínimo es 0',
            max: 9999999999999,
            min: 0,
        },
        ingresos_mensuales: {
            value: 0,
            header: 'Estimado de Ingresos Mensuales:',
            required: 'Valor requerido',
            maxLength: 'valor máximo superado',
            minLength: 'Valor mínimo es 0',
            max: 9999999999999,
            min: 0,
        },
        egresos_mensuales: {
            value: 0,
            header: 'Estimado de Egresos Mensuales:',
            required: 'Valor requerido',
            maxLength: 'valor máximo superado',
            minLength: 'Valor mínimo es 0',
            max: 9999999999999,
            min: 0,
        },
        fuente_ingresos: {
            value: '',
            header: 'Declare su fuente principal de Ingresos:',
            required: 'Campo es requerido',
            maxLength: 'Máximo 200 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
        },
        regulado_uafe: {
            value: null,
            header: 'La actividad económica de la empresa es regulada por la Unidad de Analisis Financiero (UAFE)',
            required: 'Selección requerida',
        },
        tiene_politicas: {
            value: null,
            header: 'Cuenta la empresa con políticas y procedimientos basados en leyes y disposiciones destinadas a la Prevención de Lavado de Activos, Financiamiento del Terrorismo y otros Delitos?',
            required: 'Selección requerida',
        },
        si_pep: {
            value: null,
            header: '',
            required: 'Selección requerida',
        },
        si_pep_familia: {
            value: null,
            header: '',
            required: 'Selección requerida',
        },
    }
    optionLabel = 'nombre';
    cab1 = <><span style={{ fontSize: '10px', alignContent: 'justify' }}><b>Definiciones:</b><br />El Reglamento General a la Ley Orgánica de Prevención, Detección y Erradicación del
        Delito de Lavado de Activos y del Financiamiento de Delitos, establece como "<b>Personas Expuestas Políticamente (PEP)</b>.- Son todas aquellas personas naturales, nacionales
        o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en una organización internacional,
        conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control respectivo. Los familiares de las Personas Expuestas
        Políticamente (PEP), sean estos los cónyuges o personas unidas bajo el régimen de unión de hecho, familiares hasta el<b>segundo grado de consanguinidad o primero de afinidad</b>; y,
        el o<b>los colaborador (es) directo (s)</b> esto es la (s) persona (s) natural (es) con las cuales una Persona Expuesta Políticamente (PEP), se encuentre asociada o vinculada
        societariamente, o mantenga otro tipo de relaciones empresariales, comerciales o laborales estrechas, serán consideradas como PEP."<br /><b>Declaración:</b></span></>
    cab2 = <><span style={{ fontSize: '10px', alignContent: 'justify' }}>En el caso de que su respuesta sea <b>positiva</b>, sírvase llenar el formulario de la <b>última sección (sección PEP)</b> e indicar:
        cargo/función, entidad donde desempeña tal función, o relación con la Persona Expuesta Políticamente</span></>
    cab3 = <><span style={{ fontSize: '10px', alignContent: 'justify' }}><b>Nota: Grados de consanguinidad (</b>1C-Padres, 1C-Hijos, 2C-Abuelos, 2C-Hermanos, 2C-Nietos.<b>) Grados de Afinidad
        (</b>1A Cónyuge, 1A-Suegros, 1A-Yerno/Nuera, 1A-Hijastro (a)).</span></>
    strBVG = <span><p>Conocedor (a) de las disposiciones de la Ley Orgánica de Prevención, Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos y la Ley Orgánica de Protección de Datos Personales, autorizo y doy
        mi consentimiento libre, específico, informado e inequívoco, expresamente a BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, a obtener, tratar y custodiar mis datos personales, sensibles durante el tiempo establecido en las
        referidas leyes, conforme los principios de lealtad, transparencia, reserva y confidencialidad que las normativas citadas establecen y las que constan en la Política de Privacidad de la BOLSA DE VALORES DE GUAYAQUIL S.A.
        BVG, información que debe utilizarse únicamente para realizar los análisis y verificaciones que se considere necesarios para mi vinculación con la BOLSA DE VALORES DE GUAYAQUIL S.A. BVG; así como, informar a las
        autoridades competentes en caso de llegar a determinar la existencia de actividades, y/o transacciones Inusuales o Injustificadas. En virtud de lo antes autorizado, renuncio a instaurar por este motivo cualquier tipo de acción
        civil, penal o administrativa en contra de BOLSA DE VALORES DE GUAYAQUIL S.A. BVG o sus funcionarios y empleados.</p>
        <p>Conocedor (a) de las penas de perjurio, declaro bajo juramento que la información consignada en el presente documento y el origen de mis recursos son completamente lícitos, no provienen ni están relacionados con ninguna
        actividad u operación ilícita, o vinculados con negocios de lavado de dinero producto de narcotráfico de sustancias estupefacientes y psicotrópicas y que el destino de los fondos son y serán completamente lícitos, eximo a
        BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, de toda responsabilidad, inclusive respecto a terceros, si esta declaración fuese falsa o errónea.</p>
        <p>En caso de que la información declarada pueda variar en el futuro, me comprometo a mantener informada y actualizada a BOLSA DE VALORES DE GUAYAQUIL S.A. BVG, proporcionándole la documentación e información que
        sea necesaria durante el tiempo que dure mi relación con esta.</p></span>
    decBVG = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px', verticalAlign: 'bottom', fontWeight: 'bold' }}>EL FUNCIONARIO RESPONSABLE DEBERÁ INFORMAR AL DECLARANTE, QUE ESTE FORMULARIO ESTA BASADO EN LA POLÍTICA "CONOZCA A SU CLIENTE Y PROVEEDOR" DE LA NORMATIVA DE PREVENCIÓN, DETECCIÓN Y ERRADICACIÓN DEL DELITO DE
        LAVADO DE ACTIVOS Y DEL FINANCIAMIENTO DEL TERRORISMO Y OTROS DELITOS VIGENTE.</span>
    bgvAnexo = <><span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '11px', verticalAlign: 'bottom' }}><b>DOCUMENTACIÓN A ANEXAR:</b><br /><ul>
        <li>Registro Único de Contribuyentes.</li>
        <li>Escritura de Constitución, Estatutos sociales vigentes; y sus reformas.</li>
        <li>Nombramientos vigentes-Representantes Legales, debidamente inscritos en el Registro Mercantil.</li>
        <li>Cédula de Identidad o Pasaporte del Representante Legal, Apoderado y otras Firmas Autorizadas.</li>
        <li>Nómina de Socios o Accionistas, otorgado por el Organismo de Control.</li>
        <li>Certificado de Cumplimiento de obligaciones, otorgado por el Organismo de Control y UAFE.</li>
        <li>Estados Financieros (Auditados) máximo del año fiscal anterior.</li>
        <li>Impuesto a la Renta del año inmediato anterior o constancia de la información publica del SRI.</li>
        <li>Certificado Bancario con promedio de cifras, o estados de cuenta con movimientos de un mes.</li>
        <li>Recibo de cualquiera de los servicios básicos, de la dirección declarada de la Empresa.</li>
        </ul></span></>
    bvgRespaldo = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '11px', verticalAlign: 'bottom' }}><b>RESPALDOS DEBIDA DILIGENCIA, SEGÚN APLIQUE A LA POLÍTICA:</b><br /><ul>
        <li>Registro Civil o confirmación de identificación otros medios.</li>
        <li>Servicio de Rentas Internas (Ruc e Impuesto a renta causado)</li>
        <li>Función Judicial (procesos legales)</li>
        <li>Ministerio del Interior (Certificado de Antecedentes Penales) o verificación NUI</li>
        <li>Contraloría General del Estado (Declaración Juramentada sector público-PEP´s)</li>
        <li>Listas UAFE de Sentenciados y Homónimos</li>
        <li>Lista de Personas Expuestas Políticamente PEP´S</li>
        <li>Lista consolidada ONU</li>
        <li>Superintendencias de Bancos, SCVS, SEPS, según aplique.</li>
        <li>Otras fuentes, Listas WC1, Interpol, Fiscalía, Registro de la Propiedad, etc.</li>
        </ul></span>
}
