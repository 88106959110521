import { Panel } from "primereact/panel";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../../components/InputComponent";
import { useEffect, useRef, useState } from "react";
import { CatalogoService } from "../../../service/CatalogoService";
import { Generico } from "../../../components/Generico";
import { Button } from "primereact/button";
import { InfoCliPro } from "../../../models/cpn/InfoCliPro";
import { Fieldset } from 'primereact/fieldset';
import { PersonaNaturalService } from "../../../service/personaNatural/PersonaNaturalService";

export const DatosGenerales = ({ id, catalogos, toast, seccion, nuevoCliente, next, permss }) => {

    const omodel = new InfoCliPro();
    const model = omodel.model;
    const { funcError, funDefault, funcSuccess, stringToDate, renewValues } = Generico();
    const defaultValues = funDefault(model);
    const catalogoSrv = new CatalogoService();
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues });
    const { inDrop, inText, inCalendar, inDropMultiple, inMask } = InputComponent({ omodel, errors, control, permss, id });
    const [ecuador, setEcuador] = useState(false);
    const [ciudades, setCiudades] = useState(null);
    const clientepnSrv = new PersonaNaturalService();
    const [load, setLoad] = useState(false);
    const [esCasado, setEsCasado] = useState(true);
    const isMountedRef = useRef(false);

    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (id != null && isMountedRef.current) {
            editFicha();
        }
    }, [id, isMountedRef]);

    const onChangePais = (e) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvincia = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }

    const onChangeEstadoCivil = (e) => {
        setEsCasado(false);
        validarCasado(e.value);
    }

    const validarCasado = (value) => {
        setEsCasado(false);
        if (!value) {
            setEsCasado(true);
        } else {
            catalogos?.casado.forEach(element => {
                if (element.cat_id === value.cat_id) {
                    setEsCasado(true);
                }
            });
        }
    }

    const editFicha = () => {
        clientepnSrv.consultarClientepn(id, seccion).then(res => {
            const cats = catalogos;
            let _clientepn = res.data.clientepn;
            if (!_clientepn || _clientepn === false) {
                return;
            }
            const lista = {
                pais: cats.pais,
                nacionalidad: cats.nacionalidad,
                tipo_formulario: cats.tipoFormulario,
                tipo_identificacion: cats.tipo,
                genero: cats.genero,
                estado_civil: cats.estado_civil,
                pais_dd: cats.pais,
                nacionalidad_cy: cats.nacionalidad,
                tipo_identificacion_cy: cats.tipo,
                pais_tr: cats.pais,
                genero_cy: cats.genero,
            };
            if(_clientepn.tipos_solicitante) {
                _clientepn.tipos_solicitante = cats.tipoSolicitante.filter(item => _clientepn.tipos_solicitante.includes(item.cat_id));
            }
            if (res.data.ciudades !== undefined) {
                lista['ciudad'] = res.data.ciudades;
                lista['provincia'] = cats.provincias;
                setCiudades(res.data.ciudades);
            }
            Object.keys(lista).forEach(function (key) {
                if (_clientepn[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _clientepn[key]) {
                            _clientepn[key] = element;
                        }
                    });
                }
            });
            setEcuador(JSON.stringify(_clientepn.pais) === JSON.stringify(cats.ecuador));
            if (_clientepn.fecha) {
                _clientepn.fecha = stringToDate(_clientepn.fecha);
            }
            let _esCasado = false;
            cats.casado.forEach(element => {
                if (element.cat_id === _clientepn.estado_civil.cat_id) {
                    _esCasado = true;
                }
            });

            setEsCasado(_esCasado);

            _clientepn.fecha_nacimiento = stringToDate(_clientepn.fecha_nacimiento);
            if (_esCasado) {
                _clientepn.fecha_nacimiento_cy = stringToDate(_clientepn.fecha_nacimiento_cy);
            }
            nuevoCliente({id: _clientepn?.id, nemonico: _clientepn?.nemonico, pep: _clientepn?.pep});
            validarCasado(_clientepn.estado_civil);
            reset(renewValues(_clientepn, omodel.model));
        });
    }

    const onSubmit = (data) => {
        data.seccion = seccion;
        data.fecha = data.fecha.toISOString().split('T')[0];
        data.fecha_nacimiento = data.fecha_nacimiento.toISOString().split('T')[0];
        if (esCasado) {
            data.fecha_nacimiento_cy = data.fecha_nacimiento_cy.toISOString().split('T')[0];
            data.telefono_trabajo_cy = data.telefono_trabajo_cy?.replaceAll("_", '');
        }
        data.ec = catalogos.ecuador.cat_id;
        data.casado = catalogos.casado;
        data.telefono_celular = data.telefono_celular?.replaceAll("_", '');
        setLoad(true);
        if (id == null) {
            clientepnSrv.newClientepn(data).then(res => {
                setLoad(false);
                nuevoCliente(res.data);
                funcSuccess(res, toast);
                next();
            }).catch(error => {
                setLoad(false);
                funcError(error, toast, setError);
            });
        }
        else {
            clientepnSrv.editarClientepn(data).then(res => {
                setLoad(false);
                nuevoCliente(res.data);
                next();
                funcSuccess(res, toast);
            }).catch(error => {
                setLoad(false);
                funcError(error, toast, setError);
            });
        }
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="formgrid grid">
                {inCalendar('fecha')}
                {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                {!ecuador && inText('canton')}

                {inDropMultiple('tipos_solicitante', catalogos?.tipoSolicitante, { lab: omodel.optionLabel })}
                {inDrop('tipo_formulario', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
            </div>

            <Panel header="1.- Datos Generales/Personales-Representante Legal-Apoderado-Operador-Proveedor-Otros:" className="panel-ficha">
                <div className="formgrid grid">
                    {inText('apellidos')}
                    {inText('nombres')}
                    {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel })}
                    {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel })}

                    {inText('identificacion')}
                    {inDrop('pais_dd', catalogos?.pais, { lab: omodel.optionLabel })}
                    {inDrop('estado_civil', catalogos?.estado_civil, { lab: omodel.optionLabel, chn: onChangeEstadoCivil })}
                    {inDrop('genero', catalogos?.genero, { lab: omodel.optionLabel })}

                    {inText('lugar_nacimiento')}
                    {inCalendar('fecha_nacimiento')}
                    {inText('direccion')}
                    {inMask('telefono_celular')}

                    {inText('detalle_sl')}
                    {inText('email')}
                </div>
                {esCasado && <Fieldset legend="Datos del Cónyuge/Conviviente (Si es aplicable)" >
                    <div className="formgrid grid">
                        {inText('apellidos_cy')}
                        {inText('nombres_ec')}
                        {inDrop('nacionalidad_cy', catalogos?.nacionalidad, { lab: omodel.optionLabel })}
                        {inDrop('tipo_identificacion_cy', catalogos?.tipo, { lab: omodel.optionLabel })}

                        {inText('identificacion_cy')}
                        {inDrop('pais_tr', catalogos?.pais, { lab: omodel.optionLabel })}
                        {inDrop('genero_cy', catalogos?.genero, { lab: omodel.optionLabel })}
                        {inText('lugar_nacimiento_cy')}

                        {inCalendar('fecha_nacimiento_cy')}
                        {inText('lugar_trabajo_cy')}
                        {inText('cargo_cy')}
                        {inText('email_trabajo_cy')}
                        {inMask('telefono_trabajo_cy')}
                    </div>
                </Fieldset>}
            </Panel>
            <br />

            <Button label="Siguiente" icon="pi pi-chevron-right" type="submit" loading={load} disabled={load} />
        </form>
    )
}
