import { Card } from "primereact/card"
import { Panel } from "primereact/panel"
import { GenericComponent } from "./GenericComponent";
import { FiltroReporte } from "../models/administracion/FiltroReporte";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ReporteService } from "../service/admin/ReporteService";
import { InputComponent } from "./InputComponent";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Divider } from 'primereact/divider';
import { useToken } from "./useToken";

export const Reporte = ({ url, uindex, nameReport, esBvg = false }) => {
    const generic = new GenericComponent();
    const omodel = new FiltroReporte();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError } = useForm({ defaultValues });
    const toast = useRef(null);
    const [load, setLoad] = useState(false);
    const reporteSrv = new ReporteService();
    const { inText, inCalendar } = InputComponent({ registers: null, omodel, generic, errors, control, permss, id: null });
    const { getRol } = useToken();

    useEffect(() => {
        reporteSrv.consultar(uindex).then(res => {
            setPermss(res.data);
            reset(defaultValues);
        })
    }, []);

    const onSubmit = (data) => {
        setLoad(true);
        if (data.fecha_inicio) {
            data.fecha_inicio = generic.dateToString(data.fecha_inicio);
        } else {
            delete data.fecha_inicio;
        }
        if (data.fecha_fin) {
            data.fecha_fin = generic.dateToString(data.fecha_fin);
        } else {
            delete data.fecha_fin;
        }
        if (getRol()) data.rol = JSON.stringify(getRol());

        reporteSrv.buscar(data, url).then(res => {
            setLoad(false);
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nameReport); // Replace with your desired file name
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(async (error) => {
            setLoad(false);
            const responseObj = await error.response.data.text();
            error.response.data = JSON.parse(responseObj);
            generic.funcError(error, toast, setError);
        });
    }

    return (
        <>
            <Toast ref={toast} />
            <Card>
                <Panel header="Filtros - Generación" style={{ width: '25%', margin: 'auto', minWidth: '200px' }} className="panel-ficha">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formgrid grid">
                            {inText('identificacion', { obl: 'no' }, 12)}
                            {inText('nombre', { obl: 'no' }, 12)}
                            {!esBvg && inText('compania', { obl: 'no' }, 12)}
                            {inCalendar('fecha_inicio', { obl: 'no' }, 6)}
                            {inCalendar('fecha_fin', { obl: 'no' }, 6)}
                            <div className="field col-12" style={{ textAlign: 'center' }}>
                                <Divider style={{ width: '80%', margin: 'auto' }} />
                            </div>
                            <div className="field col-12" style={{ textAlign: 'center' }}>
                                <Button label="Generar" style={{ maxWidth: '120px' }} type="submit" icon="pi pi-file-excel" className="p-button-warning" loading={load} />
                                &nbsp;
                                <Button label="Limpiar" style={{ maxWidth: '120px' }} icon="pi pi-stop" type="button" disabled={load} className="p-button-warning" onClick={() => reset(defaultValues)} />
                            </div>
                        </div>
                    </form>
                </Panel>
            </Card>
        </>
    )
}
