import { Panel } from "primereact/panel"
import { ReferenciaBancaria } from "../ReferenciaBancaria";
import { useEffect, useState } from "react";
import { DeclaracionCpn } from "../../../models/cpn/DeclaracionCpn";
import { Divider } from "primereact/divider";
import { Generico } from "../../../components/Generico";
import { PersonaNaturalService } from "../../../service/personaNatural/PersonaNaturalService";
import { Dialog } from "primereact/dialog";
import { FileBvg } from "../../../components/FileBvg";
import { FileComponent } from "../../../components/FileComponent";
import { Button } from "primereact/button";
import { ReferenciaComercial } from "../ReferenciaComercial";

export const Ampliada = ({ id, toast, tipo, permss, setDialogClientepn, cancelar, generic, urls, estado, reiniciar, handlePrev }) => {
    const omodel = new DeclaracionCpn();

    const { tableBancariaBVG, dialogoBancaria, loadLazyBancaria } = ReferenciaBancaria({ tipo: tipo, id: id, toast, permss, bvg: true, num: 5 });
    const { tableComercialBVG, dialogoComercial, loadLazyComercial } = ReferenciaComercial({ tipo: tipo, id: id, toast, permss, bvg: true, num: 6 });
    const { buttonsFormsReports, funcMessageBlobError, downloadPdf } = Generico();
    const clientepnSrv = new PersonaNaturalService();
    const [loadReport, setLoadReport] = useState(false);
    const [dialogSubir, setDialogSubir] = useState(false);
    const { enviarFormularioPep } = FileComponent({ generic, toast });

    useEffect(() => {
        loadLazyBancaria();
        loadLazyComercial();
    }, [id]);

    const generarReporte = () => {
        setLoadReport(true);
        clientepnSrv.descargar(id).then(res => {
            downloadPdf(res, id + '-Formulario-Declarante.pdf');
            setLoadReport(false);
        }).catch(error => {
            setLoadReport(false);
            funcMessageBlobError('Complete la información antes de generar.', error, toast);
        });
    }

    const subirReporte = () => {
        setDialogSubir(true);
    }

    const enviar = () => {
        enviarFormularioPep(id, urls.uenviar, tipo, reiniciar);
    }

    return (
        <>
            {tableBancariaBVG()}
            <br />

            {tableComercialBVG()}
            <br />

            <Panel header="DECLARACIÓN Y AUTORIZACIÓN" className="panel-ficha">
                <div className="formgrid grid">
                    <div className="field col-12" style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}>
                        {omodel.strNatBVG}
                    </div>
                    <div className="field col-5 h-2rem" style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '10px', verticalAlign: 'bottom', fontWeight: 'bold' }}>
                        <Divider />
                        <span>Firma del Declarante</span>
                    </div>
                    <Divider />
                    {omodel.decBVG}
                </div>
            </Panel>
            <br />

            <Panel header="INFORMACIÓN Y DOCUMENTACIÓN ANEXA (CHECK LIST)." className="panel-ficha">
                <div className="formgrid grid">
                    <div className="field col-5">
                        {omodel.bgvAnexo}
                    </div>
                    <div className="field col-1">
                        <Divider layout="vertical" />
                    </div>
                    <div className="field col-6">
                        {omodel.bvgRespaldo}
                    </div>
                </div>
            </Panel>
            <br />

            <div className="formgrid grid">
                <div className="field col-1">
                    <Button label="Anterior" type="button" icon="pi pi-chevron-left" onClick={e => { handlePrev(); }} />
                </div>
                <div className="field col-10" style={{ textAlign: 'center' }}>
                {buttonsFormsReports(setDialogClientepn, generarReporte, subirReporte, enviar, loadReport, id != null, cancelar, false)}
                </div>
            </div>

            {dialogoBancaria()}
            {dialogoComercial()}
            <Dialog visible={dialogSubir} header="Gestión de anexos" modal className="p-fluid modal-ssize" onHide={e => setDialogSubir(false)} style={{ width: '800px' }}>
                <FileBvg generic={generic} tipo={tipo} urls={urls} idp={id} toast={toast} permss={permss} estado={estado} reiniciar={reiniciar} setDialogSubir={setDialogSubir} />
            </Dialog>
        </>
    )
}
