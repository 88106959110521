import { useEffect, useRef, useState } from "react";
import { GenericComponent } from "../../components/GenericComponent";
import { Gestion } from "../../models/administracion/Gestion";
import { InputComponent } from "../../components/InputComponent";
import { GestionAppService } from "../../service/admin/GestionAppService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { useForm } from "react-hook-form";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";

export const GestionApp = () => {
    const generic = new GenericComponent();
    const toast = useRef(null);
    const omodel = new Gestion();
    const [permss, setPermss] = useState(null);
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues });
    let loadLazyTimeout = null;
    const [registros, setRegistros] = useState(null);
    const { table, setLoading, setTotalRecords, lazyParams, inDrop, inText } = InputComponent({ registers: registros, omodel, generic, errors, control, permss, id: getValues('id') });
    const gestionSrv = new GestionAppService();
    const [dialogGestion, setDialogGestion] = useState(false);
    const [load, setLoad] = useState(false);
    const [codigo, setCodigo] = useState(null);
    const [dialogFecha, setDialogFecha] = useState(null);
    const [lista, setLista] = useState(null);

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]);

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        loadLazyTimeout = setTimeout(() => {
            let _lazyParams = lazyParams;
            delete _lazyParams.rtipo;
            if (permss == null) {
                _lazyParams.rtipo = true;
            }
            gestionSrv.getGestion({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
                setTotalRecords(res.data.total);
                setRegistros(res.data.registros);
                setLoading(false);
                if (res.data.perss !== undefined) {
                    setPermss(res.data.perss);
                }
            });
        });
    }

    const openNew = () => {
        setDialogGestion(true);
        reset(defaultValues);
    }

    const onSubmit = (data) => {
        if (getValues('id') == null) {
            gestionSrv.newGestion(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogGestion(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            gestionSrv.editarGestion(data).then(res => {
                generic.funcSuccess(res, toast);
                setDialogGestion(false);
                loadLazyData();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editGestion = (data) => {
        gestionSrv.consultarGestion(data.id).then(res => {
            let _gestion = res.data;
            const lista = { respaldo: generic.sino };

            Object.keys(lista).forEach(function (key) {
                if (_gestion[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _gestion[key]) {
                            _gestion[key] = element;
                        }
                    });
                }
            });
            reset(generic.renewValues(_gestion, omodel.model));
            setDialogGestion(true);
        });
    };

    const confirmDelete = (data) => {
        confirmDialog({
            message: '¿Está seguro de eliminar la información?',
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            accept: () => eliminarInformacion(data)
        });
    }

    const eliminarInformacion = (data) => {
        gestionSrv.eliminarGestion(data.id).then(res => {
            loadLazyData();
            generic.funcSuccess(res, toast);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const confirmEjecutar = (data) => {
        confirmDialog({
            message: `¿Está seguro de ejecutar?`,
            header: `Confirmación: ${data.nombre}`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => ejecutar(data)
        });
    }

    const ejecutar = (data) => {
        setCodigo(data.id);
        setLoad(true);
        gestionSrv.procesar(data.id).then(res => {
            generic.funcSuccess(res, toast);
            setLoad(false);
            setCodigo(0);
        }).catch(error => {
            generic.funcDeleteError(error, toast);
            setLoad(false);
            setCodigo(0);
        });
    }

    const mostrar = (data) => {
        setLista(null);
        gestionSrv.listar(data.id).then(res => {
            if (res.data.length > 0) {
                setLista(res.data);
                setDialogFecha(true);
            } else {
                res.message = 'Todavía no se han generado ejecuciones exitosas';
                generic.funcSuccess(res, toast);
            }
        }).catch(error => {
            generic.funcDeleteError(error, toast);
        });
    }

    const acciones = (rowData) => {
        return (
            <div className="actions">
                {permss?.actualizar && rowData.estado !== 1 &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editGestion(rowData)} title="Editar" />}
                {rowData.estado !== 1 &&
                    <Button icon="pi pi-thumbs-up" title='Ejecutar' className="p-button-rounded p-button-success mr-2" loading={load && codigo === rowData.id} onClick={() => confirmEjecutar(rowData)} />}
                <Button icon="pi pi-bars" title='Historial de ejecuciones exitosas' className="p-button-rounded p-button-success mr-2" onClick={() => mostrar(rowData)} />
                {permss?.eliminar && rowData.estado !== 1 &&
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDelete(rowData)} title="Eliminar" />}
            </div>
        );
    }

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Card style={{ maxWidth: '100%', margin: 'auto' }}>
                <Toolbar className="mb-4" start={generic.leftToolbarTemplate(openNew, 'Nuevo', permss)}
                    end={generic.rightToolbarTemplate(registros, omodel, 'Listado_bases', 'LISTADO BASES/APLICACIONES')}></Toolbar>
                {table({ action5: acciones })}
            </Card>

            <Dialog visible={dialogGestion} header={getValues('id') == null ? "Nuevo" : "Editar"} modal className="p-fluid modal-ssize" onHide={e => setDialogGestion(false)}
                style={{ maxWidth: '800px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="formgrid grid">
                        {inText('nombre', {}, 6)}
                        {inText('descripcion', {}, 6)}
                        {inDrop('respaldo', generic.sino, { lab: omodel.optionLabel }, 6)}
                        {inText('ruta', {}, 6)}
                        {inText('sigla', {}, 6)}
                    </div>

                    {generic.buttonsForms(setDialogGestion)}
                </form>
            </Dialog>

            <Dialog visible={dialogFecha} header="Lista de Históricos ejecutados" modal className="p-fluid modal-ssize" onHide={e => setDialogFecha(false)}
                style={{ maxWidth: '350px' }}>
                <ul>
                    {lista?.map((e, i) =>
                        <li key={i}>{e.fecha} - {e.estado}</li>
                    )}
                </ul>
            </Dialog>
        </>
    )
}
