import Axios from "axios";

export class PlantillaCorreoService {

    getItem(params) {
        const queryParams = params ? Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&') : '';
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/plantilla-correo/lista?${queryParams}`).then(res => res.data);
    }

    nuevo(data) {
        return Axios.post(`${process.env.REACT_APP_URL_BACK}/admin/plantilla-correo/nuevo`, data).then(res => res.data);
    }

    editar(data) {
        return Axios.put(`${process.env.REACT_APP_URL_BACK}/admin/plantilla-correo/editar`, data).then(res => res.data);
    }

    consultar(id) {
        return Axios.get(`${process.env.REACT_APP_URL_BACK}/admin/plantilla-correo/consulta/${id}`).then(res => res.data);
    }

    eliminar(id) {
        return Axios.delete(`${process.env.REACT_APP_URL_BACK}/admin/plantilla-correo/eliminar/${id}`).then(res => res.data);
    }
}
