export class Laboral {

    model = {
        id: { value: null, export: false },
        laboral: {
            value: null,
            header: 'Condición o relación Laboral:',
            required: 'Campo requerido'
        },
        cargo: {
            value: '',
            header: 'Cargo/Función que desempeñó:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        inicio_actividad: {
            value: new Date(),
            header: 'Inicio de actividades laborales o contractuales:',
            required: 'Fecha es requerido',
        },
        fin_actividad: {
            value: new Date(),
            header: 'Fin de actividades laborales o contractuales:',
            required: 'Fecha es requerido',
        },
        area: {
            value: '',
            header: 'Área/Departamento:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        departamento: {
            value: '',
            header: 'Departamento:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        ciudad_laboral: {
            value: '',
            header: 'Ciudad:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        forma_pago: {
            value: '',
            header: 'Forma de pago:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        banco: {
            value: '',
            header: 'Banco:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        banco_cuenta: {
            value: '',
            header: 'No. De Cuenta:',
            required: 'Campo requerido',
            maxLength: 'Máximo 100 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 100,
            min: 3,
        },
        relacion_pep: {
            value: null,
            header: '',
            required: 'Seleccione una opción',
        },
    }
    labelPep = <><b>Definiciones:</b><br />El Reglamento General a la Ley Orgánica de Prevención, Detección y Erradicación del Delito de Lavado de Activos y
        del Financiamiento de Delitos, establece como "<b>Personas Expuestas Políticamente (PEP)</b>.- Son todas aquellas personas naturales, nacionales o
        extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en
        una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de
        control respectivo. Los familiares de las Personas Expuestas Políticamente (PEP), sean estos los cónyuges o personas unidas bajo el régimen de unión
        de hecho, familiares hasta el <b>segundo grado de consanguinidad o primero de afinidad</b>; y, el o <b>los colaborador (es) directo (s)</b> esto es
        la (s) persona (s) natural (es) con las cuales una Persona Expuesta Políticamente (PEP), se encuentre asociada o vinculada societariamente, o mantenga
        otro tipo de relaciones empresariales, comerciales o laborales estrechas, serán consideradas como PEP.<br /><br /><b>Declaración:</b></>
    dec2 = <>me encuentro ejerciendo uno de los cargos, o tengo una relación de las citadas en el párrafo anterior de esta sección..</>
    dec3 = <>En el caso de que su respuesta sea <b>positiva</b>, sírvase llenar el formulario de la última sección <b>(sección PEP)</b> e indicar: cargo/función, entidad donde
        desempeña tal función, o relación con la Persona Expuesta Políticamente.</>
    dec4 = <><b>Nota: Grados de consanguinidad</b> (1C-Padres, 1C-Hijos, 2C-Abuelos, 2C-Hermanos, 2C-Nietos.) <b>Grados de Afinidad</b> (1A Cónyuge, 1A-Suegros, 1A-Yerno/Nuera, 1A-Hijastro (a)).</>
    nota = <span style={{ textAlign: 'justify', fontStyle: 'italic', fontSize: '10px' }}><b>Nota:</b> Si sus respuestas fueron positivas,
        sírvase llenar el formulario de cada registro, sobre la condición o relación que mantiene con la Persona Expuesta Políticamente o Públicamente (PEP´s).</span>

    optionLabel = 'nombre';
}
