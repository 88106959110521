import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { GenericComponent } from '../../../components/GenericComponent';
import { CatalogoService } from '../../../service/CatalogoService';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { InfoBvg } from '../../../models/cpj/InfoBvg';
import { PersonaJuridicaService } from '../../../service/personaNatural/PersonaJuridicaService';

export const InformacionBVG = ({ toast, catalogos, next, id, permss, nuevoCliente }) => {

    const generic = new GenericComponent();
    const omodel = new InfoBvg();
    const defaultValues = generic.default(omodel.model, null);
    const { control, formState: { errors }, handleSubmit, reset, setError, trigger, setValue } = useForm({ defaultValues });
    const [clientepj] = useState([]);
    const juridicoSrv = new PersonaJuridicaService();
    const catalogoSrv = new CatalogoService();
    const [ecuador, setEcuador] = useState(false);
    const [ciudades, setCiudades] = useState([]);
    const [ecuadorEmpresa, setEcuadorEmpresa] = useState(false);
    const [ciudadesEmpresa, setCiudadesEmpresa] = useState([]);
    const [selectedCiiu, setSelectedCiiu] = useState(null);
    const [ciius, setCiius] = useState([]);
    const [load, setLoad] = useState(false);
    const { inDrop, inText, inCalendar, inAutocomplete, inDropMultiple, inMask } = InputComponent({ registers: clientepj, omodel, generic, errors, control, permss, id });

    const onChangePais = (e) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvincia = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }

    const onChangePaisEmpresa = (e) => {
        setEcuadorEmpresa(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvinciaEmpresa = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudadesEmpresa(res.data);
        });
    }

    const searchCiiu = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCiius([...ciius]);
            }
            else {
                catalogoSrv.getActividades(event.query.toLowerCase()).then(data => {
                    setCiius(data.data);
                });
            }
        });
    }

    useEffect(() => {
        if (id != null) {
            editarFicha();
        }
    }, [id])

    const editarFicha = () => {
        juridicoSrv.consultarCliente(id, 'seccion1').then(res => {
            let cats = catalogos;
            let _clientepj = res.data.clientepj;
            const lista = {
                pais: cats.pais,
                tipo_declaracion: cats.tipoFormulario,
                nacionalidad: cats.nacionalidad,
                pais_empresa: cats.pais,
                tipo_identificacion: cats.tipo,
            };
            if (res.data.ciudades !== undefined) {
                lista['ciudad'] = res.data.ciudades;
                lista['provincia'] = cats.provincias;
                setCiudades(res.data.ciudades);
            }
            if (res.data.ciudades_empresa !== undefined) {
                lista['ciudad_empresa'] = res.data.ciudades_empresa;
                lista['provincia_empresa'] = cats.provincias;
                setCiudadesEmpresa(res.data.ciudades_empresa);
            }
            if(_clientepj.tipos_solicitante) {
                _clientepj.tipos_solicitante = cats.tipoSolicitante.filter(item => _clientepj.tipos_solicitante.includes(item.cat_id));
            }
            Object.keys(lista).forEach(function (key) {
                if (_clientepj[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _clientepj[key]) {
                            _clientepj[key] = element;
                        }
                    });
                }
            });
            setEcuador(JSON.stringify(_clientepj.pais) === JSON.stringify(cats.ecuador));
            setEcuadorEmpresa(JSON.stringify(_clientepj.pais_empresa) === JSON.stringify(cats.ecuador));
            if (_clientepj['actividad_economica'] != null) {
                _clientepj['actividad_economica'] = res.data.actividad_economica;
                setSelectedCiiu(res.data.actividad_economica);
            }

            //Para actualizaciones
            _clientepj.fecha = generic.stringToDate(_clientepj.fecha);
            _clientepj.fecha_constitucion = generic.stringToDate(_clientepj.fecha_constitucion);

            reset(_clientepj);
            reset(generic.renewValues(_clientepj, omodel.model));
        });
    }

    const onSubmit = (data) => {
        data.fecha = data.fecha.toISOString().split('T')[0];
        data.fecha_constitucion = data.fecha_constitucion.toISOString().split('T')[0];
        data.ec = catalogos.ecuador;
        data.seccion = 'seccion1';
        data.telefono_empresa = data.telefono_empresa?.replaceAll("_", '');
        setLoad(true);

        if (id == null) {
            juridicoSrv.newCliente(data).then(res => {
                setLoad(false);
                setValue('id', data.id);
                nuevoCliente(res.data);
                trigger('id');
                generic.funcSuccess(res, toast);
                next();
            }).catch(error => {
                console.log(error);
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
        else {
            data.id = id;
            juridicoSrv.editarCliente(data).then(res => {
                nuevoCliente({razon_social: data.razon_social});
                setLoad(false);
                generic.funcSuccess(res, toast);
                next();
            }).catch(error => {
                console.log(error);
                setLoad(false);
                generic.funcError(error, toast, setError);
            });
        }
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    return (
        <>
            < form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="formgrid grid">
                    {inCalendar('fecha')}
                    {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais, val: false })}
                    {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia, val: false })}
                    {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                    {!ecuador && inText('canton')}
                    {inDrop('tipo_declaracion', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
                    {inDropMultiple('tipos_solicitante', catalogos?.tipoSolicitante, { lab: omodel.optionLabel })}
                </div>

                <Panel header="1.- Datos Generales de la Empresa" className="panel-ficha">
                    <div className="formgrid grid">
                        {inText('razon_social')}
                        {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel })}
                        {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel })}
                        {inText('identificacion')}

                        {inText('tipo_empresa')}
                        {inDrop('pais_empresa', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePaisEmpresa })}
                        {ecuadorEmpresa && inDrop('provincia_empresa', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvinciaEmpresa })}
                        {ecuadorEmpresa && inDrop('ciudad_empresa', ciudadesEmpresa, { lab: omodel.optionLabel })}
                        {!ecuadorEmpresa && inText('canton_empresa')}

                        {inText('sector_economico')}
                        {inAutocomplete('actividad_economica', selectedCiiu, setSelectedCiiu, ciius, searchCiiu, { lab: 'nombre', col: 6 })}
                        {inText('lugar_constitucion')}
                        {inCalendar('fecha_constitucion')}

                        {inText('direccion_empresa')}
                        {inMask('telefono_empresa')}
                        {inText('pagina_empresa')}
                    </div>
                </Panel>

                <div className="formgrid grid">
                    <div className="field col">
                        <br />
                        <Button label="Siguiente" icon="pi pi-chevron-right" type="submit" loading={load} disabled={load} />
                    </div>
                </div>
            </form >
        </>
    )
}
