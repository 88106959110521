import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { InputComponent } from '../../../components/InputComponent';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Generico } from '../../../components/Generico';
import { FuncionarioService } from '../../../service/ficha/FuncionarioService';
import { DeclaranteBvg } from '../../../models/fun/DeclaranteBvg';
import { CatalogoService } from '../../../service/CatalogoService';

export const DatosGenerales = ({ generic, toast, id, permss, catalogos, handlePrev, next, nuevoCliente }) => {

    const omodel = new DeclaranteBvg();
    const defaultValues = generic.default(omodel.model);
    const [ecuador, setEcuador] = useState(false);
    const { control, formState: { errors }, handleSubmit, reset, setError, getValues } = useForm({ defaultValues, shouldTrim: true });
    const funSrv = new FuncionarioService();
    const catalogoSrv = new CatalogoService();
    const formRef = useRef(null);
    const { inDrop, inText, inCalendar, inMask } = InputComponent({ omodel, generic, errors, control, permss, id: getValues('id') });
    const { stringToDate } = Generico();
    const [ciudades, setCiudades] = useState([]);
    const [estudia, setEstudia] = useState(false);
    const [hayVinculado, setHayVinculado] = useState({ lab: omodel.optionLabel });

    useEffect(() => {
        editFicha();
    }, [id]);

    const onSubmit = (data) => {
        data.fecha = data.fecha.toISOString().split('T')[0];
        data.fecha_nacimiento = data.fecha_nacimiento.toISOString().split('T')[0];
        data.ec = catalogos.ecuador;
        data.casado = catalogos?.casado;
        data.seccion = 'seccion1';
        data.telefono_domicilio = data.telefono_domicilio?.replaceAll("_", '');
        data.telefono_personal = data.telefono_personal?.replaceAll("_", '');

        if (id == null) {
            funSrv.nuevo(data, 'funcionario').then(res => {
                nuevoCliente(res.data);
                generic.funcSuccess(res, toast);
                next();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
        else {
            data.id = id;
            funSrv.editar(data, 'funcionario').then(res => {
                nuevoCliente(res.data);
                generic.funcSuccess(res, toast);
                next();
            }).catch(error => {
                generic.funcError(error, toast, setError);
            });
        }
    }

    const editFicha = () => {
        funSrv.consultar(id ?? 0, 'funcionario', 'seccion1').then(res => {
            let _cat = catalogos;
            let _dec = res.data.fun;
            const _vin = { lab: omodel.optionLabel };
            if (!_dec) {
                setHayVinculado(_vin);
                reset(defaultValues);
                return;
            }
            const lista = {
                pais: _cat.pais,
                tipo_declaracion: _cat.tipoFormulario,
                pais_residencia: _cat.pais,
                nacionalidad: _cat.nacionalidad,
                estado_civil: _cat.estado_civil,
                tipo_identificacion: _cat.tipo,
                estudia: generic.sino,
                familiar_vinculado: generic.sino,
                relacion_asociacion: generic.sino,
                si_accion: generic.sino
            };
            if (res.data.ciudades !== undefined) {
                lista['ciudad'] = res.data.ciudades;
                lista['provincia'] = _cat.provincias;
                setCiudades(res.data.ciudades);
            }
            setEstudia(_dec.estudia);

            Object.keys(lista).forEach(function (key) {
                if (_dec[key] != null) {
                    lista[key].forEach(element => {
                        if (element.cat_id === _dec[key]) {
                            _dec[key] = element;
                        }
                    });
                }
            });
            if (_dec.fecha_nacimiento) _dec.fecha_nacimiento = stringToDate(_dec.fecha_nacimiento);
            if (_dec.fecha) _dec.fecha = stringToDate(_dec.fecha);
            nuevoCliente({ estado_vinculado: _dec.estado_vinculado, hay_vinculo: _dec.hay_vinculo });
            if(_dec?.estado_vinculado !== null && _dec?.estado_vinculado !== undefined){
                _vin.ne = true;
            }
            setHayVinculado(_vin);

            setEcuador(JSON.stringify(_dec.pais) === JSON.stringify(_cat.ecuador));
            reset(generic.renewValues(_dec, omodel.model));
        });
    }

    const onError = (errors) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se detectaron errores, por favor revise el formulario para corregir.', life: 3000 });
    }

    const siguiente = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(
                new Event('submit', { bubbles: true, cancelable: true })
            );
        }
    }

    const onChangePais = (e) => {
        setEcuador(JSON.stringify(e.value) === JSON.stringify(catalogos.ecuador));
    }

    const onChangeProvincia = (e) => {
        catalogoSrv.getCatalogosId(e.value.cat_id).then(res => {
            setCiudades(res.data);
        });
    }

    const onChangeEstudia = (e) => {
        setEstudia(e.value?.cat_id);
    }

    return (
        <>
            <form ref={formRef} onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="formgrid grid">
                    {inCalendar('fecha')}
                    {inDrop('pais', catalogos?.pais, { lab: omodel.optionLabel, chn: onChangePais })}
                    {ecuador && inDrop('provincia', catalogos?.provincias, { lab: omodel.optionLabel, chn: onChangeProvincia })}
                    {ecuador && inDrop('ciudad', ciudades, { lab: omodel.optionLabel })}
                    {!ecuador && inText('canton')}
                    {inDrop('tipo_declaracion', catalogos?.tipoFormulario, { lab: omodel.optionLabel })}
                </div>
                <Panel header="1.- Datos Generales/Personales:" className="panel-ficha">
                    <div className="formgrid grid">
                        {inText('nombres')}
                        {inText('apellidos')}
                        {inText('profesion')}
                        {inDrop('nacionalidad', catalogos?.nacionalidad, { lab: omodel.optionLabel })}

                        {inDrop('tipo_identificacion', catalogos?.tipo, { lab: omodel.optionLabel })}
                        {inText('identificacion')}
                        {inDrop('pais_residencia', catalogos?.pais, { lab: omodel.optionLabel })}
                        {inDrop('estado_civil', catalogos?.estado_civil, { lab: omodel.optionLabel })}

                        {inText('direccion')}
                        {inMask('telefono_domicilio', {ast: ''})}
                        {inMask('telefono_personal', {ast: ''})}
                        {inText('lugar_nacimiento')}

                        {inCalendar('fecha_nacimiento')}
                        {inText('correo_electronico')}
                        {inDrop('estudia', generic.sino, { lab: omodel.optionLabel, chn: onChangeEstudia })}
                        {estudia && inText('horario')}

                        {estudia && inText('carrera')}
                        {estudia && inText('nivel_cursado')}
                        {inDrop('familiar_vinculado', generic.sino, hayVinculado)}
                        {inDrop('relacion_asociacion', generic.sino, hayVinculado)}

                        {inDrop('si_accion', generic.sino, {lab: omodel.optionLabel})}
                    </div>
                </Panel>
            </form>
            <br />

            <div className="formgrid grid">
                <div className="field col">
                    <Button label="Anterior" icon="pi pi-chevron-left" type="button" onClick={e => handlePrev()} />&nbsp;
                    <Button label="Siguiente" type="button" icon="pi pi-chevron-right" onClick={e => siguiente()} />
                </div>
            </div>
        </>
    )
}
