export class AccionistaBvg {

    model = {
        id: {
            value: null,
            export: false
        },
        tipo_identificacion: {
            value: null,
            required: 'Tipo de identificación es requerido',
            header: 'Tipo de identificación',
        },
        identificacion: {
            value: '',
            export: false,
            header: 'Cédula/NUI/RUC/Pasaporte:',
            required: 'Identificación es requerido',
            maxLength: 'Máximo 50 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 50,
            min: 4,
            filterField: 'identificacion',
            filterPlaceholder: 'Buscar por identificación',
            table: true,
            filter: false,
            sort: true
        },
        razon_social: {
            value: '',
            export: true,
            header: 'Apellidos Nombres Completos / Razón Social:',
            required: 'Razón Social es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            filterField: 'razon_social',
            filterPlaceholder: 'Buscar por nombres',
            table: true,
            filter: false,
            sort: true
        },
        nombres: {
            value: '',
            export: true,
            header: 'Nombres',
            required: 'Nombres es requerido',
            maxLength: 'Máximo 300 caracteres',
            filterPlaceholder: 'nombres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4
        },
        apellidos: {
            value: '',
            export: true,
            header: 'Apellidos',
            required: 'Apellidos es requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 4 caracteres',
            max: 300,
            min: 4
        },
        nacionalidad: {
            value: null,
            export: false,
            header: 'Nacionalidad:',
            required: ' Nacionalidad es Requerida',
        },
        aportacion: {
            value: 0,
            header: 'Acciones o Aportaciones VALOR TOTAL:',
            min: 0,
            max: 9999999999999,
            required: 'Valor requerido.',
            maxLength: 'Excedido valor máximo',
            minLength: 'Mínimo 0',
            table: true
        },
        participacion: {
            value: 0,
            header: '% Participación:',
            min: 0,
            max: 100,
            required: 'Porcentaje requerido.',
            maxLength: 'Excedido porcentaje máximo',
            minLength: 'Mínimo 0',
            table: true
        },
        direccion: {
            value: '',
            export: true,
            header: 'Dirección/Domicilio:',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            table: true
        },
        nombre_representante: {
            value: '',
            export: true,
            header: 'Nombre del Representante Legal si el accionista es una persona jurídica.',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            table: true
        },
        es_pep: {
            value: null,
            export: false,
            header: '¿Es PEP?',
        },
        espep: {
            value: null,
            export: true,
            table: true,
            header: '¿Es PEP?',
            tag: 'SI',
            mtaq: 'Registrar su formulario Pep (ingresar botón verde) ➜',
            severity: 'danger'
        },
    }

    optionLabel = 'nombre';
}
