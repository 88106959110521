import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { ConfiguracionArchivoService } from '../../service/admin/ConfiguracionArchivoService';
import { DataTable } from 'primereact/datatable';

export const RolArchivo = ({ id, toast, permss, generic }) => {

    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const rolService = new ConfiguracionArchivoService();
    const [screar, setScrear] = useState([]);
    const [sconsultar, setSconsultar] = useState([]);
    const [seliminar, setSeliminar] = useState([]);
    const [seditar, setSeditar] = useState([]);
    const [lazyParams, setLazyParams] = useState(generic.paramLazy({ nombre: 'contains' }, null));

    useEffect(() => {
        loadLazyData(null);
    }, [lazyParams]);

    const onPage = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onSort = (event) => {
        event.filters = lazyParams.filters;
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    const loadLazyData = (event) => {
        setLoading(true);

        let _lazyParams = lazyParams;
        _lazyParams.idc = id;
        rolService.listaRol({ lazyEvent: JSON.stringify(_lazyParams) }).then(res => {
            setTotalRecords(res.data.total);
            setRoles(res.data.registros);
            setLoading(false);
            valores(res.data.registros);
        });
    }

    const valores = (items) => {
        let _consultar = [...sconsultar];
        let _crear = [...screar];
        let _editar = [...seditar];
        let _eliminar = [...seliminar];

        items.forEach(itm => {
            if (itm.consultar !== null && itm.consultar) _consultar.push(itm.idr);
            if (itm.crear !== null && itm.crear) _crear.push(itm.idr);
            if (itm.actualizar !== null && itm.actualizar) _editar.push(itm.idr);
            if (itm.eliminar !== null && itm.eliminar) _eliminar.push(itm.idr);
        });

        setSconsultar(_consultar);
        setScrear(_crear);
        setSeditar(_editar);
        setSeliminar(_eliminar);
    }

    const asignar = (e, lista, setLista, opcion) => {
        let _seleccionados = [...lista];
        if (e.checked)
            _seleccionados.push(e.value);
        else
            _seleccionados.splice(_seleccionados.indexOf(e.value), 1);
        setLista(_seleccionados);
        setLoading(true);
        rolService.asignarPermiso({ idr: e.value, idc: id, estado: e.checked, opcion }).then(res => {
            setLoading(false);
            toast.current.show({ severity: 'success', summary: 'Exitoso', detail: res.data, life: 3000 });
        }).catch(error => {
            setLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error en el servidor', detail: 'Revise Logs', life: 3000 });
        });
    }

    const actionBodyTemplateConsultar = (rowData) => {
        return (
            <div className="actions">
                <Checkbox value={rowData.idr} disabled={!permss.actualizar} checked={sconsultar.indexOf(rowData.idr) !== -1} onChange={e => asignar(e, sconsultar, setSconsultar, 0)} />
            </div>
        );
    };

    const actionBodyTemplateCrear = (rowData) => {
        return (
            <div className="actions">
                <Checkbox value={rowData.idr} disabled={!permss.actualizar} checked={screar.indexOf(rowData.idr) !== -1} onChange={e => asignar(e, screar, setScrear, 1)} />
            </div>
        );
    };

    const actionBodyTemplateEditar = (rowData) => {
        return (
            <div className="actions">
                <Checkbox value={rowData.idr} disabled={!permss.actualizar} checked={seditar.indexOf(rowData.idr) !== -1} onChange={e => asignar(e, seditar, setSeditar, 2)} />
            </div>
        );
    };

    const actionBodyTemplateEliminar = (rowData) => {
        return (
            <div className="actions">
                <Checkbox value={rowData.idr} disabled={!permss.actualizar} checked={seliminar.indexOf(rowData.idr) !== -1} onChange={e => asignar(e, seliminar, setSeliminar, 3)} />
            </div>
        );
    };

    return (
        <>
            <DataTable value={roles} lazy className="datatable-responsive" filterDisplay="row" dataKey="idr" paginator first={lazyParams.first} rows={10} totalRecords={totalRecords}
                onPage={onPage} onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder} onFilter={onFilter} filters={lazyParams.filters} loading={loading}>
                <Column field="nombre" header='Roles disponibles' sortable showFilterMenu={false} filterField='nombre' filter filterPlaceholder='Filtrar por nombre de rol' />
                <Column field="consultar" body={actionBodyTemplateConsultar} header="Consultar" style={{ width: '10%' }}></Column>
                <Column field="crear" body={actionBodyTemplateCrear} header="Crear" style={{ width: '10%' }}></Column>
                <Column field="editar" body={actionBodyTemplateEditar} header="Editar" style={{ width: '10%' }}></Column>
                <Column field="eliminar" body={actionBodyTemplateEliminar} header="Eliminar" style={{ width: '10%' }}></Column>
                <Column field="fecha" header="Fecha Asignación" style={{ width: '15%' }}></Column>
            </DataTable>
        </>
    )
}
