export class PepFuncion {

    model = {
        id: { value: null, export: false },
        idp: { value: null, export: false },
        tipo_cargo: {
            value: null,
            export: true,
            header: 'Indique el Cargo o Función que desempeña o desempeñaba, en el último año, dentro o fuera del país Usted, su cónyuge o conviviente, o su familiar cercano:',
            required: 'Campo requerido',
            table: true
        },
        funcion: {
            value: '',
            export: false,
            header: 'Función o cargo que desempeñó o desempeña:',
            required: 'Información requerida',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3,
            table: true,
            filter: false,
            sort: true
        },
        rmu: {
            value: 0,
            export: true,
            table: true,
            header: 'Remuneración Mensual Unificada:',
            required: 'Valor requerido',
            min: 0,
            minLength: 'Valor mínimo requerido es 0',
        },
        observacion: {
            value: '',
            export: false,
            table: false,
            header: 'Observación',
            required: 'Campo requerido',
            maxLength: 'Máximo 300 caracteres',
            minLength: 'Mínimo 3 caracteres',
            max: 300,
            min: 3
        },
    }
    optionLabel = 'nombre'
}
